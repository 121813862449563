import React from 'react';

import SettingsComp from '../Components/Settings/SettingsComp';

const SeetingsPage = () => {
    return (
      <div className="row mw-100 ml-auto mr-auto mt-2 mb-auto">
        <div className="col-12">
        < SettingsComp/>

        </div>

      </div>

    );
};

export default SeetingsPage;
