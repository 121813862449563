import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AidexLogo from "../../Assets/Logo/AidexLogo.svg";
import BloodGlucoseReport from "../Reports/OverviewComps/BloodGlucoseReport";
import HyperAndHypoReport from "../Reports/OverviewComps/hyperAndHypoReports";

// import SensorUsageReport from "../Reports/OverviewComps/SensorUsageReport";
import { Link } from "react-router-dom";

import TimeInRangeReport from "../Reports/OverviewComps/TimeInRangeReport";
import Loading from "../Common/Loading"

import moment from "moment";
// import _ from "lodash";
//
// import {decrypt} from "../../utils/encryption";

const NewExportComp = (props) => {
  // const dispatch=useDispatch();

  // const data=useSelector((state)=>state)
  //
  // const calculated = useSelector((state) => {
  //   return (state.cgm.currentRecordCalculationsForExport)});

  const user = useSelector((state) => {
    if(state.user && state.user.userDetails){
      return state.user.userDetails;
    }});
    const isLoading = useSelector((state) => {

        return state.cgm.isLoading;
      });
    const cgm = useSelector((state) => {

        return state.cgm.exportCgmReadings;
      });
      // // console.log("CGM FOR EXPORT", cgm);
      const currentRecordCalculationsForExport = useSelector((state) => {

          return state.cgm.currentRecordCalculationsForExport;
        });

        // const exportEvents = useSelector((state) => {
        //
        //     return state.cgm.exportEvents;
        //   });
        //   // // console.log("EXPORT EVENTS", exportEvents);

      const safari = useSelector((state) => {

      return state.auth.safari;
    });

    const [loading,setLoading]=useState(isLoading);

    // if(cgm.length === 0){
    //   setLoading(false)
    // }else if(cgm.portal_cgm_record.length>0){
    //   setLoading(true);
    // }

  // const [localData, setLocalData] = useState("");

  // const [endDate, setStartDate] = useState("");
  // const [startDate, setEndDate] = useState("");
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");

  //BG Charts Hook
  // const [tfTimeRange, setTfTimeRange] = useState([]);
  // const [tfHistoricalReadings, setTfHistoricalReadings] = useState([]);
  // const [tfBgReadings, setTfBgReadings] = useState([]);
  // const [tfAvgBgData, setTfAvgBgData] = useState([]);

  //BP Charts Hook
  // const [bpHistoricalReading, setBpHistoricalReading] = useState([]);
  // const [tfBpReadings, setTfBpReadings] = useState([]);

  // const [days, setDays] = useState("");
  // const [defaultDays, setDefaultDays] = useState("30days");
  // const [dropMenu, setDropMenu] = useState(false);
  // const [csv, setCsv] = useState("");


  // useEffect(()=>{
  //
  //   // handleExportData()
  //
  // },[])
  // const handleExportData=async()=>{
  //   let data=localStorage.getItem("export")
  //   data=decrypt(data);
  //   data=JSON.parse(data);
  //
  //   setLocalData(data);
  //
  //
  //
  // }
  //
  //
  useEffect(()=>{

    if(user !== null){

    }
  },[user])
  useEffect(()=>{
setLoading(isLoading);
},[isLoading])

useEffect(()=>{
},[loading])


  // let localDate = moment(user&&user.dateOfBirth).utc()
  //     let age = localDate.format("DD-MM-YYYY ");
  //
  //     let localStorageData=localStorage.getItem("export")
  //     localStorageData=decrypt(localStorageData);
  //     localStorageData=JSON.parse(localStorageData);

//     let startTime,endTime,lastTime;
//     let date = new Date();
//   if(localStorageData && localStorageData.time!==""){
//
//      endTime = new Date();
//     endTime.setHours(23);
//     endTime.setMinutes(59);
//     endTime.setSeconds(59);
//     if (localStorageData.time) {
//         if (localStorageData.time === "90days") {
//             date.setDate(date.getDate() - 90);
//
//         } else if (localStorageData.time === "30days") {
//             date.setDate(date.getDate() - 30);
//
//         } else if (localStorageData.time === "14days") {
//             date.setDate(date.getDate() - 14);
//         } else if (localStorageData.time === "7days") {
//             date.setDate(date.getDate() - 7);
//
//         } else if (localStorageData.time === "today") {
//
//         }
//     }
//   }else if (localStorageData.start && localStorageData.end) {
//      startTime = new Date(localStorageData.start);
//
//      lastTime = new Date(localStorageData.end);
//
// }
const div2PDF=(e)=>{
  window.print();
}

const convert = () => {
    // e.preventDefault();

    // "\uFEFF"+
    if(cgm && cgm.portal_cgm_record && cgm.portal_cgm_record.length > 0){

    let dataNew =[];
    cgm.portal_cgm_record.forEach((obj)=>{


      let object = {"deviceSn": obj.deviceSn,
       "time": obj.time, "glucose": obj.glucose}

      dataNew.push(object);

    })

    const parsedJson = dataNew;
    if (
      !Array.isArray(parsedJson) ||
      !parsedJson.every((p) => typeof p === "object" && p !== null)
    ) {
      return;
    }
    const heading = Object.keys(parsedJson[0]).join(",");
    const space = "\n"
    const body = parsedJson.map((j) => Object.values(j).join(",")).join("\n");
    // setCsv(`${heading}${space}${body}`);
    let csvData = heading + space + body;

    if(safari){
      download(csvData,"GlucoRx Aidex CGM Record", 'text/csv;charset=utf-8;');

    }else{
    download("\uFEFF"+csvData,"GlucoRx Aidex CGM Record", 'text/csv;charset=utf-8;');
    }
  }
  };

  // Function to download data to a file
const download=( processedData, filename, type)=> {
  let data = processedData;

    var file = new Blob([data], {type: type});
    if (window.navigator.msSaveOrOpenBlob) // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
    else { // Others
        var a = document.createElement("a"),
                url = URL.createObjectURL(file);
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
}

  return ( <div className='m-auto printing-screen' id="printing-screen">

    <div className=" shadow-lg bg-white rounded pb-2 row w-100 m-auto">
    <div className="col-12 d-flex justify-content-between print-btn mt-5">

        <div className="col-12 col-md-5">
          <Link to="/dashboard"> <span className='btn btn-secondary print-btn'>Go back</span></Link>

        </div>
        <div className="col-12 col-md-7 d-md-flex align-items-end justify-content-end">
        <button className="btn btn-primary print-btn mt-0" style={{backgroundColor: "#1f3c90"}} onClick={()=>convert()}>Download CSV</button>

        <button className="btn btn-primary print-btn mt-0 ml-5" style={{backgroundColor: "#1f3c90", marginRight:"2%"}} onClick={(e) => div2PDF(e)}>Export to PDF</button>
        </div>



    </div>

      <div className="mt-4 w-100 m-auto">
        <div className="row flex-column print-head">
          <div className="d-flex justify-content-center ">
            <img src={AidexLogo} alt="Logo" width="" className="mt-4 logo-gen"/>
          </div>
          <div className="d-flex justify-content-between font-weight-bold px-5 my-3">
          <div className="ml-5">
            <div>
              Name: {user&&user.givenName?user.givenName:user&&user.name?user.name: "N/A"} {user && user.surname?user.surname:"N/A"}
            </div>
            <div>
               Email: {user&&user.email ?user.email:"N/A"}
            </div>
            {/*<div>
               Address: {user&&user.address?user.address:"N/A"}
            </div>*/}
          </div>

          <div className="mr-5">
          {/*  <div>
              Age: {user&&user.dateOfBirth?moment().diff(user.dateOfBirth, 'years'):"N/A"}
            </div>
            <div>
              D.O.B: {/*user&&user.dateOfBirth?age:"N/A"*/}
            {/*</div>
            <div>
               Phone: {user&&user.phone?user.phone:"N/A"}
            </div>
            */}
          </div>

          </div>


        <div className='text-center font-weight-bold mt-2 mb-4'>
         <div>
         <span>

           From: {moment(currentRecordCalculationsForExport && currentRecordCalculationsForExport.fromDate ?currentRecordCalculationsForExport.fromDate:0).utc().format("DD-MM-YYYY ")}

         </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         <span>

           To: {moment(new Date()).utc().format("DD-MM-YYYY ")}

         </span>
       </div>

        </div>
      </div>
        <div className="row mw-100 m-auto">

          <div className="col-12 overlays-data">
            <div className="row w-100 m-auto">
              <div className="col-12">
                <h1 className="text-center bg-common px-2 py-2 font-weight-bold text-white">Overview</h1>
              </div>
              {cgm && cgm.portal_cgm_record && cgm.portal_cgm_record.length >0 ?
                <>

              <div className=" col-12 mt-3 print-test mb-3">
                <div className="bg-white rounded shadow">
                <BloodGlucoseReport  data={cgm.portal_cgm_record} diet={cgm.diet_record} medicine={cgm.medication_record} exercise={cgm.exercise_record} insulin={cgm.insulin_record} graph="cgm"/>

                </div>
              </div>

              <div className=" col-12 mt-3 print-test mb-3">
                <div className="bg-white rounded shadow">
                <BloodGlucoseReport data={currentRecordCalculationsForExport.hoursArr} graph="agp"/>

                </div>
              </div>
              {/*<div className="col-12 my-md-0 my-5">
                <div className="bg-white rounded shadow">
                <object  type="text/html" data={currentRecordCalculationsForExport.assesmentUrl} height="10000px" width="100%" title="description"></object>

                </div>
              </div>*/}

              <div className="col-12 print-test timeRange my-md-0 my-5" style={{ height: "300px" }} >
                <div className="bg-white rounded shadow">
                  <TimeInRangeReport chartId="overviewChart" data={currentRecordCalculationsForExport}/>
                </div>
              </div>


              <div className="col-12 my-md-0 my-5">

              <div className="row  mr-sm-0  mt-2 justify-content-center align-items-center">
                <div className=" col-md-12 print-test" style={{ height: "700px" }}>
                  <div className="bg-white rounded shadow" style={{ height: "100%" }}>
                  <HyperAndHypoReport dataPoints={currentRecordCalculationsForExport.hypoglycemiaReadings} graph="hypo"/>
                  </div>
                </div>
              </div>

              <div className="row  mr-sm-0  mt-2 justify-content-center align-items-center">
                <div className=" col-md-12 print-test" style={{ height: "700px" }}>
                  <div className="bg-white rounded shadow" style={{ height: "100%" }}>
                  <HyperAndHypoReport dataPoints={currentRecordCalculationsForExport.hyperReadings} graph="hyper"/>
                  </div>
                </div>
              </div>

              <div className="row  mr-sm-0  mt-2 justify-content-center align-items-center">
                <div className=" col-md-12 print-test" style={{ height: "700px" }}>
                  <div className="bg-white rounded shadow" style={{ height: "100%" }}>

                  <HyperAndHypoReport dataPoints={currentRecordCalculationsForExport.newTimeInRange} graph="timeRange"/>

                  </div>
                </div>
              </div>

              </div>




              </>
              :null}{
                loading === true?
              <div className="col-12">
                <Loading/>
              </div>:null}
              {cgm && cgm.portal_cgm_record && cgm.portal_cgm_record.length === 0 && isLoading === false ? <div className="col-12">
              <h1 className="text-center pt-5 m-auto">No data found.</h1> </div>:null}
            </div>
          </div>
        </div>
      </div>
    </div>

    </div>
  );
};

export default NewExportComp;
