
export const API_BASE_URL = "https://grxaidex.co.uk/api";

// export const API_BASE_URL = "https://dev.grxaidex.co.uk/api";





export const loginURL = "/login";

export const fetchUserURL = "/me";

export const fetchCgmRecordUrl = "/portal/cgm-record";

export const fetchHcpurl = "/HCPProfile";
export const updateHcpUrl = "/HCPProfileUpdate";

export const fetchHcpListUrl = "/HCP-List";
// export const deleteHcpUrl = "/HCP/";






// export const fetchBgDataUrl = "/device/e_getData/";
export const fetchSettingsUrl = "/settings/get";

export const updateSettingsUrl = "/settings/update";

export const fetchSortedCgmRecord = "/portal/sorted-cgm-record";

export const FEEDBACK_URL = "/portal/post-feedback";
