import React from "react";
import HcpManagement from "../Components/Reports/Hcp/Hcp-Management";
import { default as Titles } from "../utils/document-titles";



const  HcpManagementPage = () => {
    document.title = Titles.hcp;

    return (

    <div className="container-fluid h-100 bg-white p-0">

        <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <main className="col  bg-faded ">
        <HcpManagement/>

         </main>
    </div>
</div>
  );
};

export default  HcpManagementPage;
