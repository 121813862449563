import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import $ from "jquery";
import Loading from '../../Common/Loading';


am4core.useTheme(am4themes_animated);

function TimeInRangeStacked(props) {
  const [showGraph, setShowGraph]=useState(false);

// // // console.log("PROPS DATA", props);

  useEffect(() => {

      let chart = am4core.create(props.graph, am4charts.XYChart);

    chart.data = props.dataPoints;

    chart.colors.step = 2;
    chart.padding(30, 30, 10, 30);
    chart.legend = new am4charts.Legend();

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "time";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
categoryAxis.title.text="[bold] Time "

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.minWidth = 50;
    valueAxis.title.text="[bold]% of Events "


    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);
    series1.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
    series1.name = "Hypoglycemia";
    series1.dataFields.categoryX = "time";
    series1.dataFields.valueY = "hypo";
    series1.dataFields.valueYShow = "totalPercent";
    series1.dataItems.template.locations.categoryX = 0.5;
    series1.stacked = true;
    series1.tooltip.pointerOrientation = "vertical";
    series1.fill = "#d61a00";

    let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    bullet1.label.fontSize="10";
    bullet1.label.fill = am4core.color("#ffffff");
    bullet1.locationY = 0.5;

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(80);
    series2.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.0')}%";
    series2.name = "Normal";
    series2.dataFields.categoryX = "time";
    series2.dataFields.valueY = "normal";
    series2.dataFields.valueYShow = "totalPercent";
    series2.dataItems.template.locations.categoryX = 0.5;
    series2.stacked = true;
    series2.tooltip.pointerOrientation = "vertical";
    series2.fill="#61a311";

    let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    bullet2.label.fontSize="10";
    bullet2.locationY = 0.5;
    bullet2.label.fill = am4core.color("#ffffff");

    let series3 = chart.series.push(new am4charts.ColumnSeries());
    series3.columns.template.width = am4core.percent(80);
    series3.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
    series3.name = "Hyperglycemia";
    series3.dataFields.categoryX = "time";
    series3.dataFields.valueY = "hyper";
    series3.dataFields.valueYShow = "totalPercent";
    series3.dataItems.template.locations.categoryX = 0.5;
    series3.stacked = true;
    series3.tooltip.pointerOrientation = "vertical";
    series3.fill="#f38f20";

    let bullet3 = series3.bullets.push(new am4charts.LabelBullet());
    bullet3.interactionsEnabled = false;
    bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    bullet3.label.fontSize="10";

    bullet3.locationY = 0.5;
    bullet3.label.fill = am4core.color("#ffffff");


    chart.events.on('ready', () => {
      setShowGraph(true);
    });
    // series.mainContainer.mask = undefined;

    return () => {
          chart.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataPoints]);

  return (<div className="w-100">
  <div className={showGraph?"d-none":" w-100"}>                    <Loading/>
</div><div id={props.graph} className={showGraph?"":"d-none"} style={{ width: "100%", minHeight: "600px" }}></div> </div>);
}
export default TimeInRangeStacked;
