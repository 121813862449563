import * as actions from "../actionTypes";
import { API_BASE_URL, fetchUserURL, fetchHcpurl, updateHcpUrl, fetchHcpListUrl } from "../../utils/urls";
import http from "../../utils/http-service";
import { loginSuccess,logout } from "./auth";
import ShowToast from "../../utils/show-toast";


const fetchUserStart = () => {
  return { type: actions.FETCH_USER_START };
};
const fetchUserFailed = () => {
  return { type: actions.FETCH_USER_FAILED };
};
const fetchUserSuccess = (payload) => {
  return { type: actions.FETCH_USER_SUCCESS, payload };
};
export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(fetchUserStart());
    const result = await http.get(`${API_BASE_URL}${fetchUserURL}`, {});

    if (result.status === 200 && result.data.code ===200) {
      const  data = result.data;

      dispatch(loginSuccess({ content:{token: localStorage.getItem("AidexAuthToken") }}));

      dispatch(fetchUserSuccess({ user: data }));
    }
    return result;
  } catch (error) {
    dispatch(logout());
    dispatch(fetchUserFailed());
    throw error;
  }
};

const fetchHCPStart = () => {
  return { type: actions.FETCH_HCP_START };
};

const fetchHCPFailed = () => {
  return { type: actions.FETCH_HCP_FAILED };
};

const fetchHCPSuccess = (payload) => {
  return { type: actions.FETCH_HCP_SUCCESS, payload };
};

export const fetchHCP = (user) => async (dispatch) => {
  dispatch(fetchHCPStart());

  try {
      const result = await http.get(`${API_BASE_URL}${fetchHcpurl}`);
      // // // console.log("RESULT", result);
      if (result.status === 200 ) {
        const  data = result.data;
        dispatch(fetchHCPSuccess({ user: data }));
      }
    return result;
  } catch (error) {
    dispatch(fetchHCPFailed());
    throw error;
  }
};


export const updateHCP = (user) => async (dispatch) => {
  // // // console.log("USER", user);
  try {
      const result = await http.put(`${API_BASE_URL}${updateHcpUrl}`,{
        connection_code:user.connection_code,
        name:user.name,

        date_of_birth:user.date_of_birth,

        address_line_1:user.address_line_1,
        address_line_2:user.address_line_2,
        postal_code:user.postal_code,
        country_code:user.country_code,
        phone_no:user.phone_no
      });
      // // // console.log("RESULT", result);
      if (result.status === 200 && result.data.code === 200) {
        // const  data = result.data;
        ShowToast(result.data.message,"success");


      }else {
        ShowToast(result.data.message,"error");
      }
    return result;
  } catch (error) {
    throw error;
  }
};


const fetchHCPListStart = () => {
  return { type: actions.FETCH_HCP_LIST_START };
};

const fetchHCPListFailed = () => {
  return { type: actions.FETCH_HCP_LIST_FAILED };
};

const fetchHCPListSuccess = (payload) => {
  return { type: actions.FETCH_HCP_LIST_SUCCESS, payload };
};

export const fetchHCPList = (user) => async (dispatch) => {
  dispatch(fetchHCPListStart());

  try {
      const result = await http.get(`${API_BASE_URL}${fetchHcpListUrl}`);
      // // // console.log("RESULT", result);
      if (result.status === 200 ) {
        const  data = result.data;
        // console.log("DATA :", data);
        dispatch(fetchHCPListSuccess({ hcpList: data }));
      }
    return result;
  } catch (error) {
    dispatch(fetchHCPListFailed());
    throw error;
  }
};
