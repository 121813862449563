import React from "react";

import BloodGlucose from "../Charts/BloodGlucose";
import BloodGlucoseExpo from "../Charts/BloodGlucoseExpo";
import { useSelector} from "react-redux";


// import { useHistory } from "react-router-dom";
const BloodGlucoseReport = (props) => {
  // let history = useHistory();
  // // // // console.log("PROPS IN REPORT", props);
  const cgmReadings = useSelector((state)=> state.cgm.cgmReadings);


  return (
    <div className='h-100 d-flex w-100 flex-column'>

    <div className="chart-heading mt-3 loginNew-HCP-panel">
      <span className="bg-common px-2 py-2 font-weight-bold text-white">
      {props.graph && props.graph==="cgm"?
        "CGM Readings":"Stats"}
      </span>
    </div>



  <div className="d-flex flex-column justify-content-center px-3 mt-3 mb-4 h-100">
{props.graph && props.graph==="cgm"?
    <BloodGlucose data={props.data && props.data.length>0? props.data:cgmReadings} diet={props.diet} medicine={props.medicine} exercise={props.exercise} insulin={props.insulin}/>
    :
    <BloodGlucoseExpo data={props.data} />
  }


  </div>
  </div>
  );
};

export default BloodGlucoseReport;
