import React, { Component } from 'react';
import LoginNewComp from '../Components/Auth/LoginNewComp';

class LoginNewPage extends Component {
    render() {
        return (
            <div>
                <LoginNewComp/>
            </div>
        );
    }
}

export default LoginNewPage;