import React from "react";
import HistoryComp from "../Components/Reports/HistoryComp";
import { default as Titles } from "../utils/document-titles";



const  PatternPage = () => {
    document.title = Titles.history;

    return (

    <div className="container-fluid h-100 bg-white p-0">

        <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">

        <main className="col  bg-faded ">
        <HistoryComp/>

         </main>
    </div>
</div>
  );
};

export default  PatternPage;
