import React from 'react';
import {useSelector} from 'react-redux';


const SettingsComp = () => {
  const {user} = useSelector((state)=>{return state});
    return (
        <section className="row settings m-auto mw-100 mt-5 bg-white rounded shadow mx-1 pl-md-2 pl-0 py-2">
          <div className="col-12 settings-heading mt-5">
            <h1>Settings</h1>
          </div>
          <div className="col-12 col-md-6 m-auto ">
          <form>



            <div className="row justify-content-center text-white">



            <div className="col-12 settings-low">

                <div className="form-group ml-5">
                <h3>You can change your thresholds from the GlucoRx Aidex Applications.</h3>

                </div>
              </div>
              <div className="col-12 settings-low">

                  <div className="form-group ml-5">
                  <label htmlFor="lowPoint" className="text-black">Low Value</label>
                    <input
                      type="number"
                      className="text-black"

                      placeholder="Low Value"
                      id="lowPoint"
                      value={user && user.userDetails? user.userDetails.low:'N/A'}
                      disabled
                      required
                    />
                  </div>
                </div>

                <div className="col-12 settings-high">

                  <div className="form-group ml-5">
                  <label htmlFor="highPoint" className="text-black">High Value</label>

                    <input
                      type="number"
                      className="text-black"
                      placeholder="High Value"
                      id="highPoint"
                      value={user && user.userDetails? user.userDetails.high:'N/A'}
                      disabled
                      required
                    />
                  </div>
              </div>


                {/*<div className="col-md-8 mt-2 ">
                  <button
                    type="submit"
                    className="login-btn btn-primary "
                  >
                    Set
                  </button>
                </div>
                <div className="col-md-8 mt-2 ">


                    <Link to="/Registration" className="login-btn btn-primary ">
                    Signup
                     </Link>
                </div>*/}


            </div>
          </form>
          </div>

        </section>
    );
};

export default SettingsComp;
