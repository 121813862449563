import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// import flag from "../../Assets/Navbar-Icons/country_flag.svg";
// import notification from "../../Assets/Navbar-Icons/notification.svg";
import avtar from "../../Assets/Logo/avtar.png";
import { NavLink } from "react-router-dom";
import Logo from "../../Assets/Logo/AidexLogo.svg";
import { useLocation } from 'react-router-dom';
import {logout} from "../../redux/actions/auth";
// import ExportData from "../Export/ExportData";


const Navbar = () => {
  const { userDetails } = useSelector((state) => {
    return state.user;
  });

  const location = useLocation();

  const dispatch = useDispatch();
  const[detail, setDetail] = useState();
  // const[topBar, setTopBar] = useState(false);
  const[activeReport, setActiveReport] = useState(false);


  // const name = {user.userDetials.length>0? user.user.user.firstName user.user.user.lastName:"Jhon Doe"}
  // const detail = () => {
  //   let data;
  //   if(props.user && props.user.length > 0) {
  //     data = props.user.user;
  //   }
  //   return data;
  // }
  useEffect(() => {

    // if(userDetails && userDetails.user && userDetails.user.role.length===1 && userDetails.user.role[0]=== 4){
    //
    //     setTopBar(true);
    // }
    // if(userDetails && userDetails.user && userDetails.user.role.length>1){
    //   for(let i = 0; i<userDetails.user.role.length; i++) {
    //     if(userDetails.user.role[i] === 4) {
    //
    //       setTopBar(true);
    //     }
    //   }
    // }
  }, [userDetails, location.pathname]);
  useEffect(() => {


    if(userDetails){

      setUser(userDetails);
    }
    if(
    location.pathname==="/Overview"||
    location.pathname==="/dashboard"||
    location.pathname==="/History"
  ) {
      setActiveReport(true);
    }else {
      setActiveReport(false);

    }
  }, [userDetails,location.pathname]);
  const setUser = (user) => {

    setDetail(user);

  }

  const setLoggedOut=async()=>{
     await dispatch(logout())
    // // // console.log("LOGGED OUT :", res);
  }

  return (


    <nav className="navbar navbar-expand-lg navbar-light row mw-100 m-auto p-0">
    <div className="col-3 d-none d-md-block" ref={(node) => {
  if (node) {
    node.style.setProperty("padding-left", "0px", "important");
  }
  }}>
      <img src={Logo} className="nav-logo" alt="NAV LOGO"></img>
    </div>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse col" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto row w-100 m-auto nav-tabs">
    <div className={document.documentElement.clientWidth>767 ?"col-12 col-lg-9 d-flex justify-content-center":"col-12 col-lg-9"}>
        <li role="presentation" className={document.documentElement.clientWidth>767 ?"w-25 text-center":"text-center"}>
          <NavLink
            className={activeReport? "active-route tab-pane fade show w-100 d-block h-100":"h-100 tab-pane fade show w-100 d-block"}
                to="/Overview"
              >
                <span className="icon mt-2 icon-report"></span>
                <p className="mt-1">Report</p>
              </NavLink>
            </li>
            <li role="presentation" className={document.documentElement.clientWidth>767 ?"w-25 text-center":"text-center"}>
              <NavLink
                className={location.pathname==="/Settings"?"active-route tab-pane fade show w-100 d-block h-100": "h-100 tab-pane fade show w-100 d-block"}
                to="/Settings"
              >
                <span className="icon mt-2 icon-setting"></span>
                <p className="mt-1">Settings</p>
              </NavLink>
            </li>
            <li role="presentation" className={document.documentElement.clientWidth>767 ?"w-25 text-center":"text-center"}>
              <NavLink
              className={location.pathname==="/Support"?"active-route tab-pane fade show w-100 d-block h-100": "h-100 tab-pane fade show w-100 d-block"}
              to="/Support"
              >
                <span className="icon mt-2 icon-support"></span>
                <p className="mt-1">Support</p>
              </NavLink>
            </li>
            </div>
            <div className={document.documentElement.clientWidth>767 ?"col-12 col-lg-3 text-right mt-2 d-md-flex align-items-center justify-content-end": "col-12 col-md-3 text-center mt-2" }>
            <li>
            <div className="dropdown">
          <button
            className="nav-link  d-flex justify-content-center align-items-center p-0"
            id="navbarDropdownMenuLink"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{backgroundColor: "transparent"}}
          >
          <figcaption className="figure-caption pr-3">{detail&&detail.givenName?detail.givenName: "N/A"} {detail && detail.surname?detail.surname:"N/A"}</figcaption>

            <img src={avtar} width="35" height="35" className="rounded-circle" alt="profile pic"/>
          </button>
          <div
            className="dropdown-menu dropdown-menu-right text-center text-white"
            style={{ backgroundColor: "#1F3C90" }}
            aria-labelledby="dropdownMenuButton"
          >
            <button className="bg-transparent" onClick={() => setLoggedOut()}>
              <div className="">
                <span className="mr-lg-2">
                  <i className="fas fa-sign-out-alt"></i>
                </span>
                Logout
              </div>
            </button>
          </div>
        </div>
            </li>
            </div>
    </ul>

  </div>
</nav>
  );
};

export default Navbar;
