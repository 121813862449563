import React, { useState } from "react";
// import {  useHistory } from "react-router-dom";
import { useDispatch} from "react-redux";
import { default as Titles } from "../../utils/document-titles";
import { login } from "../../redux/actions/auth";
import AidexLogo from "../../Assets/Logo/AidexLogo.svg";
// import Playstore from "../../Assets/Logo/play-store.png";
// import appstore from "../../Assets/Logo/app-store.png";
import ShowToast from "../../utils/show-toast";

const LoginNewComp = () => {
  document.title = Titles.login;


  // const history = useHistory();

  // const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {


    try {
      e.preventDefault();

      // setIsLoading(true);
      await dispatch(login({ email, password }));
      // setIsLoading(false);
    } catch (err) {

      ShowToast(err.response, "error");
      // setIsLoading(false);

      // if (err.response.data.message === "Please activate your account first!") {
      //
      //
      //   history.push("/auth/resendVerificationEmail");
      // }
    }
  };
  return (
            <div className='container-fluid'>


              <div className="row  justify-content-md-between loginNew-bg justify-content-center pl-md-3 pl-lg-0 pr-0">

              <div className="col-md-5 col-11  mt-3">

                <div className="text-md-left text-center ">
                  <img src={AidexLogo} className="logo-gen" width="200px" alt="AidexLogo"></img>
                </div>
                <div className="text-md-left text-center my-5  text-uppercase">
                  <h1 className='loginNew-heading-1'><span className="font-weight-bold">Login to your</span> Aidex Account</h1>
                </div>
                <div className="text-md-left text-center mt-5">
                  <h3 className="font-weight-bold text-uppercase login-account-heading2">Sign in to manage your account.</h3>
                </div>
              </div>
              <div className="col-md-5 col-sm-6 col-11 loginNew-fieldBox  mt-5 mb-5 ">
                <form onSubmit={handleSubmit}>

                  <div className="container ">

                    <div className="row justify-content-center text-white">
                    <div className="col-md-8 pt-5">
                        <h1 className="font-weight-bold font-blue-dark">Welcome</h1>
                        <h6 className="font-weight-bold font-blue-dark">Log in to continue</h6>
                    </div>
                      <div className="col-md-8 mt-4">
                        <div className="form-group">

                          <input
                            type="text"
                            className="loginNew-form-control"
                            placeholder="Email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group">

                          <input
                            type="password"
                            className="loginNew-form-control"
                            placeholder="Password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-8">
                      {/*<div className="text-right  text-white ">
                        <Link to="/forgetPassword"><p className="font-blue">Forgot your password?</p></Link>
                      </div>*/}

                      </div>
                      <div className="col-md-8 mt-2 ">
                        <button
                          type="submit"
                          className="login-btn btn-primary "
                        >
                          Login
                        </button>
                      </div>
                      {/*<div className="col-md-8 mt-2 ">


                          <Link to="/Registration" className="login-btn btn-primary ">
                          Signup
                           </Link>
                      </div>*/}

                    </div>
                  </div>
                </form>
              </div>
            </div>

            </div>
  );
};

export default LoginNewComp;
