import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux"
import { sendFeedback } from "../../redux/actions/feedback";
import { default as Titles } from "../../utils/document-titles";

// import ShowToast from "../../utils/show-toast";

class Feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      feedback:"",
      fullName:"",
    };
  }

  componentDidMount() {
    document.title = Titles.contactUs;

    if (this.props.user && this.props.user.userDetails !== null){

      this.setState({
        firstName:this.props.user.givenName,
        lastName:this.props.user.surname,
        email:this.props.user.email,
        fullNameL:this.props.user.firstName+" "+this.props.user.lastName
      })

    } else {
    }

  }
  componentWillReceiveProps(nextProps, nextContext) {

    if (nextProps.user && nextProps.user.userDetails !== null){

      this.setState({
        firstName:nextProps.user.givenName,
        lastName:nextProps.user.surname,
        email:nextProps.user.email,
        fullName: nextProps.user.firstName +" "+nextProps.user.lastName,
      })

    }
  }

  handleChange = (event) => {
  this.setState({
    [event.target.name]: event.target.value
    })
  }
  handleSubmitForm = async(event) => {
    event.preventDefault();

    let res = await this.props.dispatch(sendFeedback({message:this.state.feedback}));

    if (res.status === 200) {
      document.getElementById("feedback").value = "";
    }

  }
  getFullName=()=>{
    let fullName=this.state.firstName +" "+this.state.lastName

    return fullName;
  }


  //
  render() {

    return (
      <div className="container my-5 h-75 feedback">

        <form autoComplete="off" className="h-100" onSubmit={this.handleSubmitForm}>
          <span className="text-center mb-4 mt-5 "></span>
          <fieldset className="h-100">
            <div className="row h-100">
            <div className="col-12 col-md-8 m-auto">
            <div className="row">
            <div className="col-12 col-md-6">
              {" "}
              <div className="form-group">
                <div className="input-group ">
                  <input
                    name="name"
                    placeholder="Full Name"
                    className="form-control input-md msg"
                    type="text"
                    value={this.getFullName()}
                    required
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              {" "}
              <div className="form-group">
                <div className="input-group ">
                  <input
                    name="email"
                    placeholder="Email"
                    className="form-control input-md msg"
                    type="email"

                    defaultValue={this.state.email}
                    required
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              {" "}
              <div className="form-group">
                <div className="input-group ">
                  <textarea
                    name="feedback"
                    id="feedback"
                    rows="16"
                    placeholder="Enter Your Valuable Feedback Here ..."
                    className="form-control feedback"

                    onChange={this.handleChange}

                    required
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="add-info-btn mx-1">
              Send Feedback
            </button>
            </div>
            </div>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}
function mapStateToProps(state) {

  return {
    user: state.user.userDetails,
  }
}

export default withRouter(connect(mapStateToProps)(Feedback));
