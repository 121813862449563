import React,{useState, useEffect} from "react";
import {useDispatch} from "react-redux";
import {fetchCgmDataForHistory} from "../../redux/actions/cgm";
import HistoryChart  from "./Charts/historyChart";


import moment from "moment";
import Loading from '../Common/Loading';

require('moment-timezone');


const PDF =  require("../../Assets/pdf.png");


const HistoryComp = () => {

  const [organizedData, setOrganizedData] = useState([]);

  const [filterText, setFilterText] = useState("Choose Data Range");
  const [filter, setFilter] = useState("7days");

  const [endDate,setEndDate ] = useState("");
  const [startDate, setStartDate] = useState("");

  const [rangFilter, setRangeFilter] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [daysDif, setDaysDif] = useState("");

  const [dropMenu,setDropMenu]=useState(false);



  const dispatch = useDispatch();

  useEffect(() => {

    if (filter !== "" || startDate !== "") {
      // console.log("FILTER", filter);
      let dif;
      if(filter==="7days"){dif = 7;}else if(filter === "14days"){dif = 14;}else if(filter === "30days"){dif = 30;}
      fetchReadings(dif);
    }
    return () => {
      setOrganizedData([]);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchReadings = async (dif) => {


    let duration = {};
    if (filter) {
      let data_from = findStartDate(filter);
      let data_to = findEndDate();
      duration.timeFrom = data_from;
      duration.timeTo = data_to;


    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      duration.timeTo = new Date (endDate);
    }
    const response = await  dispatch(fetchCgmDataForHistory(duration, dif));
    // // console.log("HISTORY RESPONSE", response);
    // // // console.log("HISTORY RESPONSE", response[1]);

    if (response && response.data){
      // let converted = moment.utc(new Date(response.data[1].readings[0].[0].time))
      // // console.log("converted", converted);
      // console.log("DATA :", response.data);
      setOrganizedData(response.data);
      setIsLoading(false);
    } else {
      // // console.log("IN ELSE PART", response);
      // if(response.length>0 && response[1].readings[0].length>0){
      //   let timeUTC = response[1].readings[0].[0].time;
      //   // // console.log("TIME", timeUTC);
      //   let converted = moment(timeUTC).utc();
      //   // // // console.log("CHECK", moment(converted).tz.guess());
      //
      //
      // }

      setOrganizedData(response);
      setIsLoading(false);


    }




    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }

  };



  const findStartDate = (filter) => {


    if(filter === "30days") {
      let start = moment().subtract(30, 'days');


      return new Date(start._d);

    }else if(filter==="7days") {

      let start = moment().subtract(7, 'days');


      return new Date(start._d);


    } else if (filter === "14days") {


      let start = moment().subtract(14, 'days');

      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');


      return new Date(start._d);

    } else if(filter === "24hours"){

      let start = moment().add(-24, 'hours');


      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');


      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');


      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }
useEffect(()=>{

},[organizedData])


const fetchRangeReadings=()=>{
  if(startDate !=="" && endDate !==""){
    let a = moment(startDate);
    let b = moment(endDate);
    // console.log("a", a);
    // console.log("b", b);

    let dif = moment.duration(b.diff(a)).asDays();
    // console.log("dif", dif);
    let finalDif = dif>1? dif + " Days": dif + " Day";

    setDaysDif(finalDif);
  setIsLoading(true);

  setOrganizedData([]);
  fetchReadings(dif);
}
}
    return (
        <div className="container-fluid p-0">
        <div className="row  mr-sm-0">
          <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
            <div className="bg-white rounded shadow " style={{ height: "100%" }}>


              <div className="">
                  <div className="d-flex justify-content-between mb-2">
                    <div className="chart-heading mt-3 loginNew-HCP-panel">
                      <span className="bg-common px-2 py-2 font-size2 font-weight-bold text-white">
                        History
                      </span>
                    </div>
                  </div>
                  <div className="row mw-100 m-auto justify-content-center mt-3 p-3 print-btn">
                    <div className="col-md-4 col-12 overview-border">
                      <div className="row mw-100 m-auto justify-content-between align-items-center">
                      <h3 className="col-12 col-md font-weight-bold text-black">{filter!== ""? filter : daysDif!==""? daysDif: filterText}</h3>

                        <div className="col-12 col-md bg-light p-3">{fromDate? fromDate.toString(): 0} - { toDate? toDate: 0}</div>
                      </div>
                    </div>
                    <div className="col-md-7 col-12 mt-md-0 mt-2 overview-border d-flex justify-content-around align-items-center">

                        <div className="row w-100">
                            <div className="col-md col-12">

                            <button className={
                              startDate === "" && filter === "7days"
                                ? "bg-common text-white rounded px-2 mr-2"
                                : " bg-light rounded px-2 mr-2"
                            }
                            onClick = {
                                            () => {
                                              if(filter !== "7days"){setOrganizedData([])}

                                              setStartDate("");
                                              setEndDate("");
                                              setFilter("7days");
                                              setFilterText("7days");

                                              setRangeFilter(false);
                                              setIsLoading(true);
                                              setDropMenu(false);


                                            }
                                          }
                            >7 days</button>
                            <button className={
                              startDate === "" && filter === "14days"
                                ? "bg-common text-white rounded px-2 mr-2"
                                : " bg-light rounded px-2 mr-2"
                            }
                            onClick = {
                                            () => {
                                              if(filter !== "14days"){setOrganizedData([])}

                                              setStartDate("");
                                              setEndDate("");
                                              setFilter("14days");
                                              setFilterText("14days");

                                              setIsLoading(true);
                                              setDropMenu(false);


                                            }
                                          }

                            >14 days</button>
                            <button className={
                              startDate === "" && filter === "30days"
                                ? "bg-common text-white rounded px-2 mr-2"
                                : " bg-light rounded px-2 mr-2"
                            }
                            onClick = {
                                            () => {
                                              if(filter !== "30days"){setOrganizedData([])}

                                              setStartDate("");
                                              setEndDate("");
                                              setFilter("30days");
                                              setFilterText("Month");

                                              setRangeFilter(false);
                                              setIsLoading(true);
                                              setDropMenu(false);




                                            }
                                          }

                            >30 days</button>
                            <button type="button"
                             // disabled={rangeBtn?false:true}
                            className={ rangFilter? "bg-common text-white rounded px-2 mr-2": "bg-light rounded px-2 mr-2"} onClick = {
                              () => {
                                setFilter("");
                                setFilterText("Range");

                                setRangeFilter(true);
                                setDropMenu(!dropMenu);

                              }
                            }>
                              RANGE
                            </button>

                            </div>
                            <div className="col-12 col-md d-flex">
                        {dropMenu=== true ?
                        <div className="d-flex text-center w-100">

                        <div className="d-flex flex-column flex-md-row justify-content-center align-items-center m-auto">
                        <div className='d-flex flex-column flex-md-row  mb-md-0 mb-2'>
                        <input type="date" className="m-0"
                        onChange={(e)=>setStartDate(moment(e.target.value).format("YYYY-MM-DD").toString())}
                        ></input>
                        <input type="date"
                        disabled={startDate===""?true:false}
                        className="ml-1 mt-0 mb-0 mr-0"
                        onChange={(e)=>setEndDate(moment(e.target.value).format("YYYY-MM-DD").toString())}
                        min={moment(startDate).format("YYYY-MM-DD")}
                        max={moment().format("YYYY-MM-DD")}
                        ></input>
                        </div>
                        <button
                        className="bg-common text-white rounded px-2 ml-2"
                        disabled={endDate===""?true:false}

                        onClick={fetchRangeReadings}
                        >Go</button>
                        </div>

                        </div>

                        :null}
                            </div>

                        </div>




  </div>
  <div className="col-12 col-md-1 d-flex">
  <button
    type="button"
    className="p-0 m-0"
    onClick={()=>window.print()}
    style={{background: "none"}}
      // onClick={()=>history.push({
      //             pathname:"/export-data",
      //
      //           })}
  >
  <img src={PDF} className="pdf-img" style={{width:"25px"}} alt="PDF Icon"/>
  <p className="m-0">Export</p>

    {/* <i className="fas fa-file-export"></i>*/}
  </button>
  </div>

                  </div>
                </div>
                </div>
              </div>

            </div>



        <div className="row  mr-sm-0  mt-2 justify-content-center">
          <div className=" col-md-12" style={{ height: "100%" }}>
            <div className="bg-white rounded shadow " style={{ height: "100%" }}>

              <div className='h-100 d-flex flex-column justify-content-md-between justify-content-center px-3 pt-2'>
            <div className="d-flex flex-column justify-content-between  mt-5 pt-2 mb-4" style={{minHeight:"590px"}}>
            {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?
            organizedData.map((week, index)=> (
              <div className="w-100" key={index+1}>
                {week && week.readings && week.readings[0].length>0?<HistoryChart chartId={"HistoryReport"+ index}  data={week}/>:null}
              </div>
            ))

            :
            (organizedData === undefined || organizedData.length === 0) && isLoading === false?<h1 className="text-center pt-5 m-auto">No data found.</h1>:
            <Loading/>

          }


            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default HistoryComp;
