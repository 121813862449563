
import React, { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import {useSelector} from 'react-redux';
import Loading from '../../Common/Loading';
import insulinIcon from "../../../Assets/charts/insulin.svg";
import exerciseIcon from "../../../Assets/charts/exercise.svg";
import dietIcon from "../../../Assets/charts/diet.svg";
import medicineIcon from "../../../Assets/charts/medicine.svg";
import moment from "moment";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
// Themes end
function CGM(props) {
  const [showGraph, setShowGraph]=useState(false);
  const {user} = useSelector((state)=>{return state});

  // let chart;
  // const location = useLocation();
// // // // console.log("props.",props);
  useEffect(() => {


    let chart = am4core.create("CGM", am4charts.XYChart);

// Add data
// chart.data = props.data;
let insulineSeriesData ;
// = props.insulin;
// // // console.log("insulineSeriesData", insulineSeriesData);
if(props.insulin && props.insulin.length>0){
   insulineSeriesData = props.insulin.map((ob)=>{

      let timeUTC = ob.time;
      // let converted = moment(timeUTC).utc();
      // // // console.log("CHECK", moment(converted).tz.guess());

    const gue =  moment.tz(timeUTC, "UTC");
      let conTime = moment(gue._d).format("HH:mm");


      let obj = {insulinName: ob.insulinName, time: gue._d, dosage: ob.dosage, time2: conTime}
      return obj;

  });
}
// // // console.log("TIME FOR INSU", moment(props.insulin[0].created_at));
let exerciseSeriesData ;
if(props.exercise && props.exercise.length>0){
   exerciseSeriesData = props.exercise.map((ob)=>{
// // console.log("ob", ob);
      let timeUTC = ob.time;
      // // console.log("timeUTC", timeUTC);
      // let converted = moment(timeUTC).utc();
      // // // console.log("CHECK", moment(converted).tz.guess());

    const gue =  moment.tz(timeUTC, "UTC");
      let conTime = moment(gue._d).format("HH:mm");


      let obj = {
          exercise_id: ob.exercise_id,
          user_id: ob.user_id,
          time: ob.time,
          intensity: ob.intensity,
          duration: ob.duration,
          created_at: ob.created_at,
          updated_at: ob.updated_at,
          time2: conTime
      }

      return obj;

  });
}
// // console.log("exerciseSeriesData", exerciseSeriesData);



let medicineSeriesData;
if(props.medicine && props.medicine.length>0){
   medicineSeriesData = props.medicine.map((ob)=>{
// // console.log("ob", ob);
      let timeUTC = ob.time;
      // // console.log("timeUTC", timeUTC);
      // let converted = moment(timeUTC).utc();
      // // // console.log("CHECK", moment(converted).tz.guess());

    const gue =  moment.tz(timeUTC, "UTC");
      let conTime = moment(gue._d).format("HH:mm");


      let obj = {
          medicine_id: ob.medicine_id,
          user_id: ob.user_id,
          time: ob.time,
          medicine_name: ob.medicine_name,
          dosage: ob.dosage,
          created_at: ob.created_at,
          updated_at: ob.updated_at,
          time2: conTime
      }

      return obj;

  });
}
// // console.log("medicineSeriesData", medicineSeriesData);

let dietSeriesData;

if(props.diet && props.diet.length>0){
   dietSeriesData = props.diet.map((ob)=>{

      let timeUTC = ob.time;
      // let converted = moment(timeUTC).utc();
      // // // console.log("CHECK", moment(converted).tz.guess());

    const gue =  moment.tz(timeUTC, "UTC");
      let conTime = moment(gue._d).format("HH:mm");


      let obj = {food: ob.food, time: gue._d, carbohydrate: ob.carbohydrate, time2: conTime}
      return obj;

  });
}
// // console.log("dietSeriesData", dietSeriesData);

// // // // console.log("insulineSeriesData :", insulineSeriesData);
// // // // console.log("exerciseSeriesData :", exerciseSeriesData);
// // // // console.log("medicineSeriesData :", medicineSeriesData);
// // // // console.log("dietSeriesData :", dietSeriesData);


chart.data = props.data && props.data.length > 0 && props.data.map(record => {
// chart.data = customeData.map(record => {

let color= record.glucose>=user.userDetails.high? "#f38f20": record.glucose<= user.userDetails.low ? "#d61a00": "#2a93fc";

  return {
    time: new Date(record.time),
    glucose: record.glucose,
    time2: record.time2,
    color: color
  }
});
// let dataLength = chart.data.length - 1;


chart.dateFormatter.dateFormat = "dd-MM-yyyy";
  // Create axes
let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
// dateAxis.startLocation = 0.5;
dateAxis.dateFormatter = new am4core.DateFormatter();
dateAxis.dateFormatter.dateFormat = "dd-YYYY";
dateAxis.cursorTooltipEnabled = false;



// Create value axis
let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis.min = 0;
valueAxis.title.text = "mmol/L";
valueAxis.cursorTooltipEnabled = false;

let valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis());
valueAxis2.renderer.opposite = true;
valueAxis2.syncWithAxis = valueAxis;
valueAxis2.hidden = true;
valueAxis2.cursorTooltipEnabled = false;











// Create series

let lineSeries = chart.series.push(new am4charts.LineSeries());
lineSeries.dataFields.valueY = "glucose";
lineSeries.dataFields.dateX = "time";
lineSeries.strokeWidth = 4;
lineSeries.fillOpacity = 0;
lineSeries.sequencedInterpolation = false;

let bullet = lineSeries.bullets.push(new am4core.Circle());
    bullet.stroke = am4core.color("#fff");
    bullet.radius = 4;
    bullet.propertyFields.fill = "color";
    bullet.tooltipText = "Date: {dateX} \n Time: {time2} \n Reading: {valueY} mmol/L";
// Insulin Series
let insulineSeries = chart.series.push(new am4charts.LineSeries());
insulineSeries.dataFields.valueY = "dosage";
insulineSeries.dataFields.dateX = "time";
insulineSeries.strokeWidth = 0;
insulineSeries.fillOpacity = 0;
insulineSeries.sequencedInterpolation = false;
insulineSeries.data= insulineSeriesData;
insulineSeries.yAxis = valueAxis2;
let insulineBullet = insulineSeries.bullets.push(new am4charts.Bullet());
let insulinImage = insulineBullet.createChild(am4core.Image);
insulinImage.href = insulinIcon;
insulinImage.width = 30;
insulinImage.height = 30;
insulinImage.horizontalCenter = "top";
insulinImage.verticalCenter = "middle";
insulineBullet.tooltipText = "Date: {dateX} \n Time: {time2} \n Dosage: {valueY} \n Insulin Name: {insulinName}";

// Diet series
let dietSeries = chart.series.push(new am4charts.LineSeries());
dietSeries.dataFields.valueY = "carbohydrate";
dietSeries.dataFields.dateX = "time";
dietSeries.strokeWidth = 0;
dietSeries.fillOpacity = 0;
dietSeries.sequencedInterpolation = false;
dietSeries.data= dietSeriesData;
dietSeries.yAxis = valueAxis2;
let dietBullet = dietSeries.bullets.push(new am4charts.Bullet());
let dietImage = dietBullet.createChild(am4core.Image);
dietImage.href = dietIcon;
dietImage.width = 30;
dietImage.height = 30;
dietImage.horizontalCenter = "middle";
dietImage.verticalCenter = "middle";
dietBullet.tooltipText = "Date: {dateX} \n Time: {time2} \n carbohydrate: {carbohydrate} \n Food: {food}";



// Exercise Series
let exerciseSeries = chart.series.push(new am4charts.LineSeries());
exerciseSeries.dataFields.valueY = "duration";
exerciseSeries.dataFields.dateX = "time";
exerciseSeries.strokeWidth = 0;
exerciseSeries.fillOpacity = 0;
exerciseSeries.sequencedInterpolation = false;
exerciseSeries.data= exerciseSeriesData;
exerciseSeries.yAxis = valueAxis2;
let exerciseBullet = exerciseSeries.bullets.push(new am4charts.Bullet());
let exerciseImage = exerciseBullet.createChild(am4core.Image);
exerciseImage.href = exerciseIcon;
exerciseImage.width = 30;
exerciseImage.height = 30;
exerciseImage.horizontalCenter = "middle";
exerciseImage.verticalCenter = "middle";
exerciseBullet.tooltipText = "Date: {dateX} \n Time: {time2} \n Duration: {valueY} \n Intensity: {Intensity}";



// Medicine Series
let medicineSeries = chart.series.push(new am4charts.LineSeries());
medicineSeries.dataFields.valueY = "dosage";
medicineSeries.dataFields.dateX = "time";
medicineSeries.strokeWidth = 0;
medicineSeries.fillOpacity = 0;
medicineSeries.sequencedInterpolation = false;
medicineSeries.data= medicineSeriesData;
medicineSeries.yAxis = valueAxis2;
let medicineBullet = medicineSeries.bullets.push(new am4charts.Bullet());
let image = medicineBullet.createChild(am4core.Image);
image.href = medicineIcon;
image.width = 30;
image.height = 30;
image.horizontalCenter = "middle";
image.verticalCenter = "middle";
medicineBullet.tooltipText = "Date: {dateX} \n Time: {time2} \n Dosage: {valueY} \n Medicine Name: {medicine_name}";


/* Create legend and enable default markers */
chart.legend = new am4charts.Legend();
// chart.legend.useDefaultMarker = true;

/* Remove square from marker template */
let marker = chart.legend.markers.template;
marker.disposeChildren();
marker.width = 40;
marker.height = 40;




chart.scrollbarX = new am4core.Scrollbar();
chart.scrollbarX.parent = chart.bottomAxesContainer;

chart.scrollbarX.thumb.minWidth = 5;
chart.scrollbarX.startGrip.disabled = true;
chart.scrollbarX.endGrip.disabled = true;
// chart.scrollbarX.startGrip.width=10;
// chart.scrollbarX.endGrip.width=10;

let range = valueAxis.axisRanges.create();
range.value = 0;
range.endValue = user.userDetails.low;
// // // console.log("user.userDetails.low", user.userDetails.low);
range.axisFill.fill = am4core.color("#ff7dc561");
range.axisFill.fillOpacity = 0.2;
range.grid.strokeOpacity = 0;

let range1 = valueAxis.axisRanges.create();
range1.value = user.userDetails.high;
range1.endValue = 30;
range1.axisFill.fill = am4core.color("#ffff008c");
range1.axisFill.fillOpacity = 0.2;
range1.grid.strokeOpacity = 0;

chart.cursor = new am4charts.XYCursor();
// chart.cursor.behavior= 'panXY';

chart.events.on('ready', () => {
  setShowGraph(true);
});
return () => {
      chart.dispose();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.data, props.diet, props.exercise, props.medicine, props.insulin]);

  return (<div className="">
  <div className={showGraph?"d-none":" w-100"}>                    <Loading/>
</div>
        <div id="CGM" className={showGraph?"":"d-none"} style={{ width: "100%", minHeight: "400px" }}></div>
  </div>
  );
}
export default CGM;
