import React, { Suspense, useEffect } from "react";
// import { Route } from "react-router-dom";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchUser } from "./redux/actions/user";
import LoginNewPage from "./Pages/LoginNewPage";
import Dashboard from "./Components/Dashboard";
import AssesmentReport from "./Components/Assesment/AssesmentReport";


import GuestRoute from "./utils/routes/guest-route";
import AuthenticatedRoute from "./utils/routes/authenticate-route";

import ReportsPage from "./Pages/ReportsPage";
import SupportPage from "./Pages/SupportPage";
import SettingsPage from "./Pages/SettingsPage";


// import OverlayPage from "./Pages/OverlayPage";
import HistoryPage from "./Pages/HistoryPage";
import HcpPage from "./Pages/HcpLinkPage";
import HcpManagementPage from "./Pages/HcpManagementPage";



// import HcpPage from "./Components/Reports/Hcp/Hcp-Link";


// import ExportPdf from "./Pages/exportPdf";
// import ExportData from "./Components/Export/ExportData";
import Feedback from "./Components/Feedback/Feedback";

import NewExportData from "./Components/Export/NewExportData";







function ReactRouter () {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
  },);

    return (
      <React.Fragment>
      <Router>
      <Suspense fallback={() => <div>loading...</div>}>
          <Switch>
            <GuestRoute exact path="/" component={LoginNewPage} />
            <GuestRoute exact path={"/auth/login"} component={LoginNewPage} />
            <GuestRoute exact path="/Login" component={LoginNewPage} />

            <AuthenticatedRoute exact path="/Overview" component={ReportsPage} />
            <AuthenticatedRoute exact path="/History" component={HistoryPage} />
            <AuthenticatedRoute exact path="/Settings" component={SettingsPage} />

            <AuthenticatedRoute
            exact
            path={"/dashboard"}
            component={Dashboard}
          />



            <AuthenticatedRoute exact path="/Support" component={SupportPage} />
            <AuthenticatedRoute exact path={"/Feedback"} component={Feedback} />
            <AuthenticatedRoute exact path={"/export-data"} component={NewExportData} />
            <AuthenticatedRoute exact path={"/hcp-link"} component={HcpPage} />
            <AuthenticatedRoute exact path={"/hcp-list"} component={HcpManagementPage} />

            <AuthenticatedRoute exact path={"/cgm-assesment-report"} component={AssesmentReport} />





            <Redirect from="*" to="/auth/login" />
          </Switch>
        </Suspense>
      </Router>
      <ToastContainer />
      </React.Fragment>
    );
}

export default ReactRouter;
