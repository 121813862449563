import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
// import { useHistory } from "react-router-dom";
// import {fetchExportData} from "../../../redux/actions/export";
import { useSelector, useDispatch } from "react-redux";
// import {encrypt,decrypt} from "../../utils/encryption";
import {makeAssesmentUrl} from "../../redux/actions/cgm";


const PDF =  require("../../Assets/pdf.png");

const AssesmentReport = () => {

  const user = useSelector((state) => {
    if(state.user && state.user.userDetails){
      return state.user.userDetails;
    }});

  const dispatch = useDispatch();
  // const history = useHistory();
  // const device = useSelector((state) =>(state.devices.devices));
  const { RangePicker } = DatePicker;
  // const [isLoading, setIsLoading] = useState(false);
  // const data = useSelector((state) => state);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [dropMenu, setDropMenu] = useState(false);
  const [days, setDays] = useState("");

  // const [repoUrl, setRepoUrl] = useState("");


  const [showButton,setShowButton]=useState(false);
  // const [exportableDays,setExportableDays]=useState(false);
  const [allowedToShow, setAllowedToShow]=useState(false);

  // const safari = useSelector((state) => {
  //   return state.auth.safari;
  //   });


  // useEffect(() => {
  //
  //   if (days !== "" || startDate !== "") {
  //     fetchReadings();
  //   }
  // }, [days, startDate]);
  const fetchReadings = async () => {


    let duration = {};
    if (days !== "") {
      let data_from = findStartDate(days);
      let data_to = findEndDate();
      duration.timeFrom = data_from;
      duration.timeTo = data_to;
// start of making url

      let startRep = data_from;
      startRep = moment(startRep).format("yyyy-MM-DD");
      let endRep = data_to;
      endRep = moment(endRep).format("yyyy-MM-DD");
      let eml = user && user.email ?user.email: null;
      if(eml !== "" && eml !== null){
        eml = eml.replace("@","%40");

      }
      let uuid = user && user.uuid ?user.uuid: null;
      let gender = user && user.gender && user.gender !== null? user.gender: "";

      let nam = user && user.name ?user.name: null;
      let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`
        // setRepoUrl(repUrl);
        duration.url = repUrl;

// end of making url


    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      duration.timeTo = new Date (endDate);


// start making of url with start date
      let startRep = new Date(startDate);
      startRep = moment(startRep).format("yyyy-MM-DD");
      let endRep = new Date (endDate);
      endRep = moment(endRep).format("yyyy-MM-DD");
      let eml = user && user.email ?user.email: null;
      if(eml !== "" && eml !== null){
        eml = eml.replace("@","%40");

      }
      let uuid = user && user.uuid ?user.uuid: null;
      let gender = user && user.gender && user.gender !== null? user.gender: "";

      let nam = user && user.name ?user.name: null;
      let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`
        // setRepoUrl(repUrl);
        duration.url = repUrl;

        // end of making url
    }
     await dispatch(makeAssesmentUrl(duration));

    return duration.url;
  };






  const findStartDate = (filter) => {


    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {

      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {


      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if(filter === "24hours"){

      let start = moment().add(-24, 'hours');
      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');
      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');
      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    // let currentDate = new Date();
    return (new Date());
  }

   //Fetch Export Data
  //  useEffect(()=>{
  //   handleExportData()
  //   },[])

  const handleExportData=async()=>{
      let urlRed = await fetchReadings();
      document.getElementById('closebtn1').click();
      window.open(urlRed,"_blank");
      // history.push({pathname:"/cgm-assesment-report",});
}



  //Handle Filters
  const handleDaysFilter = (d) => {
    setDays(d);
    setStartDate("");
    setEndDate("");
    setAllowedToShow(true);
    // if(d === "30days" || d === "90days"){
    //   setExportableDays(false);
    // }else {
    //   setExportableDays(true);
    //
    // }

  };
  //Handle Date Picker
  const handleDateChange = (val) => {
    // setCsvButton(false);

    if (val && val.length > 0) {

      setStartDate(val[1].format("YYYY-MM-DD").toString());
      setEndDate(val[0].format("YYYY-MM-DD").toString());
      // setToDate(val[1].format("YYYY-MM-DD").toString());
      // setFromDate(val[0].format("YYYY-MM-DD").toString());

    }else {
      setStartDate("");
      setEndDate("");
      // setToDate("");
      // setFromDate("");
      setAllowedToShow(false);
    }

  };
  const checkDates = ()=>{

    if(startDate !== "" && endDate !== "") {
      setDays("")
      setAllowedToShow(true)
      // let a = moment(startDate);
      // let b = moment(endDate);
      // let daysDif=b.diff(a, "days");
      // if(daysDif < 15){
      //   setExportableDays(true);
      // }else {
      //   setExportableDays(false);
      //
      // }
    }
  }


  return (
    <div className="row">
      <button
        type="button"
        className="d-flex"
        data-toggle="modal"
        data-target="#assesmentModal"
        style={{background: "none"}}
          // onClick={()=>history.push({
          //             pathname:"/export-data",
          //
          //           })}
      >
      <img src={PDF} className="pdf-img" style={{width:"25px"}} alt="PDF Icon"/>
      <p className="mt-1">CGM Assesment Report</p>

      </button>


      <div
        className="modal fade"
        id="assesmentModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="assesmentModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="assesmentModalLabel">
                CGM Assesment Report
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closebtn1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">

                <div className="row mw-100 m-auto justify-content-center">
                  <button
                    className={
                      startDate === "" && days === "7days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2 text-black"
                    }
                    onClick={() => {
                      handleDaysFilter("7days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);


                    }}
                  >
                    7 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "14days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2 text-black"
                    }
                    onClick={() => {
                      handleDaysFilter("14days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    14 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "30days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2 text-black"
                    }
                    onClick={() => {
                      handleDaysFilter("30days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    30 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "90days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2 text-black"
                    }
                    onClick={() => {
                      handleDaysFilter("90days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    90 Days
                  </button>
                  <button
                    className={
                      startDate !== ""
                        ? "bg-common text-white rounded px-2 dropdown"
                        : "bg-light rounded px-2 dropdown text-black"
                    }
                    onClick={(e) => {
                      setDropMenu(!dropMenu);
                      setAllowedToShow(false);
                      setDays("");
                      // setCsvButton(false);
                      // setOrganizedData([]);




                    }}
                  >
                    Range
                  </button>
                </div>
                <div className="row text-center mt-3 justify-content-center">
                  <div className="col-12">
                    <div className={dropMenu ? " d-block" : " d-none"}>
                      <RangePicker
                        defaultValue={
                          startDate && [moment(startDate), moment(endDate)]
                        }
                        onChange={(val) => {
                          handleDateChange(val);
                          setShowButton(!showButton);
                        }}
                      />
                      <button className="bg-common text-white rounded px-2 ml-3" onClick={()=>{checkDates()}}>Go</button>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center align-items-center my-5 mw-100 m-auto'>
                {allowedToShow?
                  <div className='col-12'>
                     <div className='row justify-content-center pdf-device'>

                        <div className='col-6'>
                       <button className="btnImgColor rounded px-3 mr-2 py-3"
                     onClick={()=>{
                        handleExportData()
                      }}>
                          <span className=''>Generate Report</span>

                      </button>
                        </div>




                     </div>
                  </div>
                  :null }
                </div>
            </div>
            <div className="modal-footer">


      </div>
          </div>
        </div>
      </div>


{/*}<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>*/}
    </div>
  );
};

export default AssesmentReport;
