export const FETCH_USER_START = "FETCH_USER_START";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";

export const FETCH_HCP_START = "FETCH_HCP_START";
export const FETCH_HCP_FAILED = "FETCH_HCP_FAILED";
export const FETCH_HCP_SUCCESS = "FETCH_HCP_SUCCESS";

export const FETCH_HCP_LIST_START = "FETCH_HCP_LIST_START";
export const FETCH_HCP_LIST_FAILED = "FETCH_HCP_LIST_FAILED";
export const FETCH_HCP_LIST_SUCCESS = "FETCH_HCP_LIST_SUCCESS";
