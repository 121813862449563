import React from "react";

import EventsChart from "../Charts/EventsChart";
import TimeInRangeStacked from "../Charts/timeInRangeStacked"
// import { useSelector} from "react-redux";


// import { useHistory } from "react-router-dom";
const HyperAndHypoReport = (props) => {
  // let history = useHistory();
  // // // // console.log("PROPS IN REPORT", props);
  // const cgmReadings = useSelector((state)=> state.cgm.cgmReadings);


  return (
    <div className='h-100 d-flex w-100 flex-column'>

    <div className="chart-heading mt-3 loginNew-HCP-panel">
      <span className="bg-common px-2 py-2 font-weight-bold text-white">
      {props.graph && props.graph==="hyper"?
        "Hyperglycemia":props.graph && props.graph==="hypo"?"Hypoglycemia": "Time In Range"}
      </span>
    </div>


{props.graph && props.graph!=="timeRange"?
<div className="d-flex flex-column justify-content-center px-3 mt-3 mb-4 h-100">
  <EventsChart dataPoints={props.dataPoints} graph={props.graph}/>
</div>
:

<div className="d-flex flex-column justify-content-center px-3 mt-3 mb-4 h-100">
  <TimeInRangeStacked dataPoints={props.dataPoints} graph={props.graph}/>
</div>
}

  </div>
  );
};

export default HyperAndHypoReport;
