import React, { useEffect, useState } from "react";

// import {Pattern} from "@amcharts/amcharts4/core";
import {useDispatch} from "react-redux";

// import Navbar from "../Components/Common/Navbar";
// import Sidebar from "../Components/Common/Sidebar";
import BloodGlucoseReport from "../Components/Reports/OverviewComps/BloodGlucoseReport";
import HyperAndHypoReport from "../Components/Reports/OverviewComps/hyperAndHypoReports";


// import OverViewReport from "../Components/Reports/OverviewComps/OverViewReport";
// import SensorUsageReport from "../Components/Reports/OverviewComps/SensorUsageReport";
import TimeInRangeReport from "../Components/Reports/OverviewComps/TimeInRangeReport";




import {fetchCgmData,resetCgmReadings} from "../redux/actions/cgm";
// import * as _ from "lodash";
import moment from "moment";
// import {DatePicker} from "antd";
import { default as Titles } from "../utils/document-titles";
import Loading from '../Components/Common/Loading';


const ReportsPage = () => {
  document.title = Titles.reports;
  // const { RangePicker } = DatePicker;
  // const user = useSelector((state) => {
  //   if(state.user && state.user.userDetails){
  //     return state.user.userDetails;
  //   }});


  const [filterText, setFilterText] = useState("Choose Date Range");

  // const [filter, setFilter] = useState("30days");

  const [filter, setFilter] = useState("24hours");

  // const [tfTimeRange, setTfTimeRange] = useState([]);
  // const [showDate, setShowDate] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  // const [todayFilter, setTodayFilter] = useState();
  // const [weekFilter, setWeekFilter] = useState();
  // const [monthFilter, setMonthFilter] = useState(true);
  const [rangFilter, setRangeFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dropMenu,setDropMenu]=useState(false);

  // const [dates, setDates] = useState([]);
  // const [hackValue, setHackValue] = useState();
  // const [value, setValue] = useState();
  const [organizedData, setOrganizedData] = useState([]);
  const [hypoglycemiaReadings, setHypoglycemiaReadings] = useState([]);
  const [hyperReadings, setHyperReadings] = useState([]);
  const [timeInRange, setTimeInRange] = useState([]);


  const [insulin, setInsulin] = useState([]);
  const [exercise, setExercise] = useState([]);
  const [diet, setDiet] = useState([]);
  const [medicine, setMedicine] = useState([]);
  const [daysDif, setDaysDif] = useState("");

  // const [repoUrl, setRepoUrl] = useState("");




  const [calculatedValues, setCalculatedValues] = useState({});





  const dispatch = useDispatch();

  useEffect(() => {
    // // // console.log("hypoglycemiaReadings", hypoglycemiaReadings);
    if (filter !== "" || startDate !== "") {
      fetchReadings();
    }
  //   return function cleanup() {
  //   dispatch(resetCgmReadings());
  // }
  return () => {
    setIsLoading(true);
    setOrganizedData([]);
    setHypoglycemiaReadings([]);
    setHyperReadings([]);
    setCalculatedValues([]);
    setDiet([]);
    setExercise([]);
    setInsulin([]);
    setMedicine([]);
    setTimeInRange([]);
    dispatch(resetCgmReadings());

    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [filter]);

  // useEffect(() => {
  //   // transformHistoricalReadings();
  // }, [data.cgmReadings]);

  const fetchReadings = async () => {
    // // // console.log("Called", startDate);
    // // // console.log("Called End", endDate);


    let duration = {};
    if (filter) {
      let data_from = findStartDate(filter);
      let data_to = findEndDate();
      duration.timeFrom = data_from;
      duration.timeTo = data_to;

      //CGM Assessment Report start

      // let startRep = data_from;
      // startRep = moment(startRep).format("yyyy-MM-DD");
      // let endRep = data_to;
      // endRep = moment(endRep).format("yyyy-MM-DD");
      // let eml = user && user.email ?user.email: null;
      // if(eml !== "" && eml !== null){
      //
      //   eml = eml.replace("@","%40");
      //
      // }
      // let uuid = user && user.uuid ?user.uuid: null;
      // let gender = user && user.gender && user.gender !== null? user.gender: "";
      //
      // let nam = user && user.name ?user.name: null;
      // let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`
      //
      //
      //   setRepoUrl(repUrl);

      //CGM Assessment Report end



    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      duration.timeTo = new Date (endDate);

      //CGM Assessment Report start

      // let startRep = new Date(startDate);
      // startRep = moment(startRep).format("yyyy-MM-DD");
      // let endRep = new Date (endDate);
      // endRep = moment(endRep).format("yyyy-MM-DD");
      // let eml = user && user.email ?user.email: null;
      // if(eml !== "" && eml !== null){
      //
      //   eml = eml.replace("@","%40");
      //
      // }
      // let uuid = user && user.uuid ?user.uuid: null;
      // let gender = user && user.gender && user.gender !== null? user.gender: "";
      //
      // let nam = user && user.name ?user.name: null;
      // let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`
      //
      //   setRepoUrl(repUrl);

      //CGM Assessment Report end

    }
    const response = await  dispatch(fetchCgmData(duration));
    // // // console.log("RES",response);

    if (response && response.data){
      // // // console.log("RES",response.data);
      setIsLoading(false);
      setOrganizedData(response.data);
      setHypoglycemiaReadings(response.hypoglycemiaReadings);
      setHyperReadings(response.hyperReadings);
      setCalculatedValues(response.calculation);
      setDiet(response.diet);
      setExercise(response.exercise);
      setInsulin(response.insulin);
      setMedicine(response.medicine);
      setTimeInRange(response.timeInRange);

    } else {
      setOrganizedData(response);

    }





    if (rangFilter === false){
    let start = findStartDate(filter);

    setFromDate(moment(start).format('D MMM YYYY').toString());
    setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  } else if (rangFilter === true) {

  }

  };




  // function handleMenuClick(e) {
  //   switch (e.item.props.value) {
  //     case "today":
  //       break;
  //     case "week":
  //       break;
  //     case "month":
  //       break;
  //     case "range":
  //       break;
  //     default:
  //       break;
  //   }
  // }

  const findStartDate = (filter) => {

    // let currentDate = new Date();

    if(filter === "30days") {

      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {


      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {



      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {


      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if(filter === "24hours"){


      let start = moment().add(-24, 'hours');
      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');
      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');
      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    let currentDate = new Date();
    return (currentDate);
  }

  const fetchRangeReadings=()=>{
    if(startDate !=="" && endDate !==""){
      let a = moment(startDate);
      let b = moment(endDate);
      let dif = moment.duration(b.diff(a)).asDays();
      let finalDif = dif>1? dif + " Days": dif + " Day";
      // // // console.log(typeof(finalDif));

      setDaysDif(finalDif);
    setIsLoading(true);

    setOrganizedData([]);
    setHyperReadings([]);
    setHypoglycemiaReadings([]);
    setTimeInRange([]);

    fetchReadings();
  }
  }


    return (

        <div className="container-fluid h-100 p-0">
            {/*<Navbar user={userData}/>*/}
            <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">
                {/*<aside className="col-12 col-md-2 bg-common py-3  rounded ">
                    <Sidebar/>
                </aside>*/}
              {/*<main className="col  bg-faded ">*/}
                    {/*<OverViewComp/>*/}
                    <div className="col-12">
                      <div className="row  mr-sm-0 ">
                        <div className="col-12 mt-md-0 mt-2"  style={{ height: "100%" }}>
                          <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                    <div className="">
                      <div className="d-flex justify-content-between mb-2">
                        <div className="chart-heading mt-3 loginNew-HCP-panel">
                          <span className="bg-common px-2 py-2 font-size2 font-weight-bold text-white">
                            Overview
                          </span>
                        </div>
                      </div>
                      <div className="row mw-100 m-auto justify-content-center mt-3 p-3">
                        <div className="col-md-4 col-12 overview-border">
                          <div className="row mw-100 m-auto justify-content-between align-items-center">
                            <h3 className="col-12 col-md font-weight-bold text-black">{filter!== ""? filter : daysDif!==""? daysDif: filterText}</h3>
                            <div className="col-12 col-md bg-light p-3"><span style={{fontSize:"0.8vw"}}>{fromDate? fromDate.toString(): 0} - { toDate? toDate: 0}</span></div>
                          </div>
                        </div>
                        <div className="col-md-8 col-12 mt-md-0 mt-2 overview-border d-flex justify-content-around align-items-center">
                        <div className="row w-100">
                            <div className="col-5">
                            <button
                            className={
                              startDate === "" && filter === "24hours"
                                ? "bg-common text-white rounded px-2 mr-2"
                                : " bg-light rounded px-2 mr-2"
                            }
                              onClick = {() => {
                                    setStartDate("");
                                    setEndDate("");
                                    //setShowDate(false);
                                    setFilter("24hours");
                                    setFilterText("1day");
                                    // setWeekFilter(true);
                                    // setTodayFilter(false);
                                    // setMonthFilter(false);
                                    setRangeFilter(false);
                                    setIsLoading(true);
                                    setDropMenu(false);


                                    if(filter !== "24hours"){
                                      setOrganizedData([]);
                                      setHypoglycemiaReadings([]);
                                      setHyperReadings([]);
                                      setTimeInRange([]);


                                    }

                                    }
                                  }>
                                          1 day
                                          </button>
                            <button className={
                              startDate === "" && filter === "7days"
                                ? "bg-common text-white rounded px-2 mr-2"
                                : " bg-light rounded px-2 mr-2"
                            }
                            onClick = {
                                            () => {
                                              if(filter !== "7days"){
                                                setOrganizedData([]);
                                                setHypoglycemiaReadings([]);
                                                setHyperReadings([]);
                                                setTimeInRange([]);

                                              }

                                              setStartDate("");
                                              setEndDate("");
                                              //setShowDate(false);
                                              setFilter("7days");
                                              setFilterText("7days");
                                              // setWeekFilter(false);
                                              // setTodayFilter(false);
                                              // setMonthFilter(false);
                                              setRangeFilter(false);
                                              setIsLoading(true);
                                              setDropMenu(false);


                                            }
                                          }
                            >7 days</button>
                            <button className={
                              startDate === "" && filter === "14days"
                                ? "bg-common text-white rounded px-2 mr-2"
                                : " bg-light rounded px-2 mr-2"
                            }
                            onClick = {
                                            () => {
                                              if(filter !== "14days"){
                                                setOrganizedData([]);
                                                setHypoglycemiaReadings([]);
                                                setHyperReadings([]);
                                                setTimeInRange([]);

                                              }

                                              setStartDate("");
                                              setEndDate("");
                                              //setShowDate(false);
                                              setFilter("14days");
                                              setFilterText("14days");
                                              // setWeekFilter(false);
                                              // setTodayFilter(false);
                                              // setMonthFilter(true);
                                              setRangeFilter(false);
                                              setIsLoading(true);
                                              setDropMenu(false);


                                            }
                                          }

                            >14 days</button>
                            <button type="button"
                             // disabled={rangeBtn?false:true}
                            className={ rangFilter? "bg-common text-white rounded px-2 mr-2": "bg-light rounded px-2 mr-2"} onClick = {
                              () => {
                                setFilterText("Range");
                                // setShowDate(true);
                                // setWeekFilter(false);
                                // setTodayFilter(false);
                                // setMonthFilter(false);
                                setRangeFilter(true);
                                setDropMenu(!dropMenu);

                              }
                            }>
                              RANGE
                            </button>
                            {/*}<button className=""
                            onClick = {
                                            () => {
                                              setStartDate("");
                                              setEndDate("");
                                              //setShowDate(false);
                                              setFilter("90days");
                                              setFilterText("90Days");
                                              // setWeekFilter(false);
                                              // setTodayFilter(false);
                                              // setMonthFilter(true);
                                              setRangeFilter(false);
                                            }
                                          }
                            >90 days</button>*/}
                            {/*<button type="button"

                            onClick = {
                () => {
                  setFilter("");
                  setFilterText("Range");
                  setShowDate(true);
                  // setWeekFilter(false);
                  // setTodayFilter(false);
                  // setMonthFilter(false);
                  setRangeFilter(true);
                  setDropMenu(!dropMenu)
                }
              }>
                RANGE
              </button>

              <div className={dropMenu==true?"dropdown-contenting d-block":"dropdown-contenting d-none"} >
                      {
                showDate && ( <RangePicker
                   defaultValue = {
                      startDate && [moment(startDate), moment(endDate)]
                    }
                                    onChange = {
                                      (val) => {
                                        setStartDate(val[1].format("YYYY-MM-DD").toString());
                                        setEndDate(val[0].format("YYYY-MM-DD").toString());
                                        setToDate(val[1].format("YYYY-MM-DD").toString());
                                        setFromDate(val[0].format("YYYY-MM-DD").toString());
                                        setDates(val);
                                      }
                                    }
                  value={hackValue || value}
      // disabledDate={disabledDate}
      // onCalendarChange={val => setDates(val)}
      // onChange={val => setValue(val)}
      // onOpenChange={onOpenChange}
                    />
                )
              }
              </div>*/}
                            </div>
                            <div className="col-7 d-flex">
              {dropMenu=== true ?
              <div className="d-flex text-center w-100">

                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                     <div className='d-flex flex-row mb-md-0 mb-2'>
                     <input type="date" className="m-0"
                    onChange={(e)=>setStartDate(moment(e.target.value).format("YYYY-MM-DD").toString())}
                    ></input>
                    <input type="date"
                    disabled={startDate===""?true:false}
                    className="ml-1 mt-0 mb-0 mr-0"
                    onChange={(e)=>setEndDate(moment(e.target.value).format("YYYY-MM-DD").toString())}
                    min={moment(startDate).format("YYYY-MM-DD")}
                    max={moment().format("YYYY-MM-DD")}
                    ></input>
                       </div>
                    <button
                    className="bg-common text-white rounded px-2 ml-2"
                    disabled={endDate===""?true:false}

                    onClick={()=>{fetchRangeReadings();setFilter("");}}
                    >Go</button>
                </div>

              </div>

                :null}
                            </div>
                        </div>


  </div>


                        {/*<div className="col-md-1 col-12 overview-border d-md-flex justify-content-around align-items-center d-none">
                        <i className="fas font-blue fa-print"></i>
                        <i className="fas font-blue fa-download"></i>
                        <i className="fas font-blue fa-envelope"></i>
                        <i className="fas font-blue fa-share"></i>
                        </div>*/}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>


                <div className="row  mr-sm-0  mt-2 justify-content-center">
                  <div className=" col-md-12" style={{ height: "500px" }}>
                    <div className={organizedData !== undefined || organizedData===null?"bg-white rounded shadow d-flex":"bg-white rounded shadow"} style={{ height: "100%" }}>
                    {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?
                    <BloodGlucoseReport data={organizedData} diet={diet} medicine={medicine} exercise={exercise} insulin={insulin} graph="cgm"/>

                    :
                    (organizedData === undefined || organizedData.length === 0) && isLoading === false?<h1 className="text-center pt-5 m-auto">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>



                <div className="row  mr-sm-0  mt-2 justify-content-center">
                  <div className=" col-md-12" style={{ height: "700px" }}>
                    <div className={organizedData !== undefined || organizedData===null?"bg-white rounded shadow d-flex":"bg-white rounded shadow"} style={{ height: "100%", overFlow:"hidden", }}>
                    {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?
                    <BloodGlucoseReport data={calculatedValues.hoursArr} graph="agp"/>

                    :
                    (organizedData === undefined || organizedData.length === 0) && isLoading === false?<h1 className="text-center pt-5 m-auto">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>


                {/*<div className="row  mr-sm-0  mt-2 justify-content-center">
                  <div className=" col-md-12">
                    <div className={organizedData !== undefined || organizedData===null?"bg-white rounded shadow d-flex":"bg-white rounded shadow"} style={{ height: "100%" }}>
                    {organizedData && organizedData !== "" && organizedData !== null && organizedData !== undefined && organizedData.length !== 0?
                    <embed src={repoUrl} className="h-7550" width="100%" title="description"/>

                    :
                    null

                  }
                    </div>
                  </div>
                </div>*/}


                {organizedData && (organizedData === "" || organizedData === null || organizedData === undefined || organizedData.length === 0)?null:

                <div className="row  mr-sm-0  mt-2">
                  <div className="col-12  timeRange my-md-0 my-5" style={{ height: "300px" }} >
                    <div className="bg-white rounded shadow " style={{ height: "100%" }}>
                      <TimeInRangeReport chartId="overviewChart" data={calculatedValues}/>
                    </div>
                  </div>
                </div>}
                {(hypoglycemiaReadings === undefined || hypoglycemiaReadings.length === 0) && isLoading === false? null :

                <div className="row  mr-sm-0  mt-2 justify-content-center align-items-center">
                  <div className=" col-md-12" style={{ height: "700px" }}>
                    <div className={hypoglycemiaReadings && hypoglycemiaReadings.length > 0? "bg-white rounded shadow":"bg-white rounded shadow d-flex" } style={{ height: "100%" }}>
                    {hypoglycemiaReadings && hypoglycemiaReadings !== "" && hypoglycemiaReadings !== null && hypoglycemiaReadings !== undefined && hypoglycemiaReadings.length !== 0?
                    <HyperAndHypoReport dataPoints={hypoglycemiaReadings} graph="hypo"/>

                    :
                    (hypoglycemiaReadings === undefined || hypoglycemiaReadings.length === 0) && isLoading === false?<h1 className="text-center pt-5 m-auto">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>}
{(hyperReadings === undefined || hyperReadings.length === 0) && isLoading === false? null :
                <div className="row  mr-sm-0  mt-2 justify-content-center align-items-center">
                  <div className=" col-md-12" style={{ height: "700px" }}>
                    <div className={hyperReadings && hyperReadings.length > 0 ?"bg-white rounded shadow":"bg-white rounded shadow d-flex"} style={{ height: "100%" }}>
                    {hyperReadings && hyperReadings !== "" && hyperReadings !== null && hyperReadings !== undefined && hyperReadings.length !== 0?
                    <HyperAndHypoReport dataPoints={hyperReadings} graph="hyper"/>

                    :
                    (hyperReadings === undefined || hyperReadings.length === 0) && isLoading === false?<h1 className="text-center pt-5 m-auto">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>}
{(timeInRange === undefined || timeInRange.length === 0) && isLoading === false? null :
                <div className="row  mr-sm-0  mt-2 justify-content-center align-items-center">
                  <div className=" col-md-12" style={{ height: "700px" }}>
                    <div className={timeInRange && timeInRange.length > 0 ?"bg-white rounded shadow":"bg-white rounded shadow d-flex"} style={{ height: "100%" }}>
                    {timeInRange && timeInRange !== "" && timeInRange !== null && timeInRange !== undefined && timeInRange.length !== 0?
                    <HyperAndHypoReport dataPoints={timeInRange} graph="timeRange"/>

                    :
                    (timeInRange === undefined || timeInRange.length === 0) && isLoading === false?<h1 className="text-center pt-5 m-auto">No data found.</h1>:
                    <Loading/>

                  }
                    </div>
                  </div>
                </div>}




              </div>

            {/*</main>*/}
            </div>
        </div>
    );
};

export default ReportsPage;
