//    const countryList = [
// 	"United Kingdom",
// 	"Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra",
//             "Angola", "Anguilla", "ntarctica", "ntigua And Barbuda",
//             "Argentina", "Armenia", "Aruba", "Australia", "Austria",
//             "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh",
//             "Barbados", "Belarus", "Belgium", "Belize",
//             "Benin", "Bermuda", "Bhutan", "Bolivia",
//             "Bosnia And Herzegovina", "Botswana", "Bouvet Island",
//             "Brazil", "British Indian Ocean Territory", "Brunei Darussalam",
//             "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
//             "Canada", "Cape Verde", "Cayman Islands", "Central African Republic",
//             "Chad", "Chile", "China", "Christmas Island", "Cocos (keeling) Islands",
//             "Colombia", "Comoros", "Congo", "Congo, The Democratic Republic Of The",
//             "Cook Islands", "Costa Rica", "Cote D'ivoire", "Croatia", "Cuba",
//             "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica",
//             "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador",
//             "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia",
//             "Falkland Islands (malvinas)", "Faroe Islands", "Fiji", "Finland",
//             "France", "French Guiana", "French Polynesia", "French Southern Territories",
//             "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar",
//             "Greece", "Greenland", "Grenada", "Guadeloupe", "Guam", "Guatemala",
//             "Guinea", "Guinea-bissau", "Guyana", "Haiti", "Heard Island And Mcdonald Islands",
//             "Holy See (vatican City State)", "Honduras", "Hong Kong", "Hungary",
//             "Iceland", "India", "Indonesia", "Iran, Islamic Republic Of", "Iraq",
//             "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", "Kazakstan",
//             "Kenya", "Kiribati", "Korea, Democratic People's Republic Of", "Korea, Republic Of",
//             "Kosovo", "Kuwait", "Kyrgyzstan", "Lao People's Democratic Republic",
//             "Latvia", "Lebanon", "Lesotho", "Liberia", "Libyan Arab Jamahiriya",
//             "Liechtenstein", "Lithuania", "Luxembourg", "Macau",
//             "Macedonia, The Former Yugoslav Republic Of", "Madagascar",
//             "Malawi", "Malaysia", "Maldives", "Mali", "Malta",
//             "Marshall Islands", "Martinique", "Mauritania", "Mauritius",
//             "Mayotte", "Mexico", "Micronesia, Federated States Of", "Moldova, Republic Of",
//             "Monaco", "Mongolia", "Montserrat", "Montenegro", "Morocco",
//             "Mozambique", "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands",
//             "Netherlands Antilles", "New Caledonia", "New Zealand", "Nicaragua", "Niger",
//             "Nigeria", "Niue", "Norfolk Island", "Northern Mariana Islands",
//             "Norway", "Oman", "Pakistan", "Palau", "Palestinian Territory, Occupied",
//             "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines",
//             "Pitcairn", "Poland", "Portugal", "Puerto Rico", "Qatar", "Reunion",
//             "Romania", "Russian Federation", "Rwanda", "Saint Helena",
//             "Saint Kitts And Nevis", "Saint Lucia", "Saint Pierre And Miquelon",
//             "Saint Vincent And The Grenadines", "Samoa", "San Marino",
//             "Sao Tome And Principe", "Saudi Arabia", "Senegal", "Serbia",
//             "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia",
//             "Solomon Islands", "Somalia", "South Africa", "South Georgia And The South Sandwich Islands",
//             "Spain", "Sri Lanka", "Sudan", "Suriname", "Svalbard And Jan Mayen",
//             "Swaziland", "Sweden", "Switzerland", "Syrian Arab Republic",
//             "Taiwan, Province Of China", "Tajikistan", "Tanzania, United Republic Of",
//             "Thailand", "Togo", "Tokelau", "Tonga", "Trinidad And Tobago",
//             "Tunisia", "Turkey", "Turkmenistan", "Turks And Caicos Islands",
//             "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
//             "United States", "United States Minor Outlying Islands", "Uruguay", "Uzbekistan",
//             "Vanuatu", "Venezuela", "Viet Nam", "Virgin Islands, British",
//             "Virgin Islands, U.s.", "Wallis And Futuna", "Western Sahara",
//             "Yemen", "Zambia", "Zimbabwe"
// ];
// export default countryList;
const countryList = [
    {
        "country": "Afghanistan",
        "code": "+93"
    },
    {
        "country": "Åland",
        "code": "+358 18"
    },
    {
        "country": "Albania",
        "code": "+355"
    },
    {
        "country": "Algeria",
        "code": "+213"
    },
    {
        "country": "American Samoa",
        "code": "+1 684"
    },
    {
        "country": "Andorra",
        "code": "+376"
    },
    {
        "country": "Angola",
        "code": "+244"
    },
    {
        "country": "Anguilla",
        "code": "+1 264"
    },
    {
        "country": "Antigua and Barbuda",
        "code": "+1 268"
    },
    {
        "country": "Argentina",
        "code": "+54"
    },
    {
        "country": "Armenia",
        "code": "+374"
    },
    {
        "country": "Aruba",
        "code": "+297"
    },
    {
        "country": "Ascension",
        "code": "+247"
    },
    {
        "country": "Australia",
        "code": "+61"
    },
    {
        "country": "Australian Antarctic Territory",
        "code": "+672 1"
    },
    {
        "country": "Australian External Territories",
        "code": "+672"
    },
    {
        "country": "Austria",
        "code": "+43"
    },
    {
        "country": "Azerbaijan",
        "code": "+994"
    },
    {
        "country": "Bahamas",
        "code": "+1 242"
    },
    {
        "country": "Bahrain",
        "code": "+973"
    },
    {
        "country": "Bangladesh",
        "code": "+880"
    },
    {
        "country": "Barbados",
        "code": "+1 246"
    },
    {
        "country": "Barbuda",
        "code": "+1 268"
    },
    {
        "country": "Belarus",
        "code": "+375"
    },
    {
        "country": "Belgium",
        "code": "+32"
    },
    {
        "country": "Belize",
        "code": "+501"
    },
    {
        "country": "Benin",
        "code": "+229"
    },
    {
        "country": "Bermuda",
        "code": "+1 441"
    },
    {
        "country": "Bhutan",
        "code": "+975"
    },
    {
        "country": "Bolivia",
        "code": "+591"
    },
    {
        "country": "Bonaire",
        "code": "+599 7"
    },
    {
        "country": "Bosnia and Herzegovina",
        "code": "+387"
    },
    {
        "country": "Botswana",
        "code": "+267"
    },
    {
        "country": "Brazil",
        "code": "+55"
    },
    {
        "country": "British Indian Ocean Territory",
        "code": "+246"
    },
    {
        "country": "British Virgin Islands",
        "code": "+1 284"
    },
    {
        "country": "Brunei Darussalam",
        "code": "+673"
    },
    {
        "country": "Bulgaria",
        "code": "+359"
    },
    {
        "country": "Burkina Faso",
        "code": "+226"
    },
    {
        "country": "Burundi",
        "code": "+257"
    },
    {
        "country": "Cape Verde",
        "code": "+238"
    },
    {
        "country": "Cambodia",
        "code": "+855"
    },
    {
        "country": "Cameroon",
        "code": "+237"
    },
    {
        "country": "Canada",
        "code": "+1"
    },
    {
        "country": "Caribbean Netherlands",
        "code": "+599 3, +599 4, +599 7"
    },
    {
        "country": "Cayman Islands",
        "code": "+1 345"
    },
    {
        "country": "Central African Republic",
        "code": "+236"
    },
    {
        "country": "Chad",
        "code": "+235"
    },
    {
        "country": "Chatham Island, New Zealand",
        "code": "+64"
    },
    {
        "country": "Chile",
        "code": "+56"
    },
    {
        "country": "China",
        "code": "+86"
    },
    {
        "country": "Christmas Island",
        "code": "+61 89164"
    },
    {
        "country": "Cocos (Keeling) Islands",
        "code": "+61 89162"
    },
    {
        "country": "Colombia",
        "code": "+57"
    },
    {
        "country": "Comoros",
        "code": "+269"
    },
    {
        "country": "Congo",
        "code": "+242"
    },
    {
        "country": "Congo, Democratic Republic of the",
        "code": "+243"
    },
    {
        "country": "Cook Islands",
        "code": "+682"
    },
    {
        "country": "Costa Rica",
        "code": "+506"
    },
    {
        "country": "Ivory Coast (Côte d'Ivoire)",
        "code": "+225"
    },
    {
        "country": "Croatia",
        "code": "+385"
    },
    {
        "country": "Cuba",
        "code": "+53"
    },
    {
        "country": "Curaçao",
        "code": "+599 9"
    },
    {
        "country": "Cyprus",
        "code": "+357"
    },
    {
        "country": "Czech Republic",
        "code": "+420"
    },
    {
        "country": "Denmark",
        "code": "+45"
    },
    {
        "country": "Diego Garcia",
        "code": "+246"
    },
    {
        "country": "Djibouti",
        "code": "+253"
    },
    {
        "country": "Dominica",
        "code": "+1 767"
    },
    {
        "country": "Dominican Republic",
        "code": "+1 809"
    },
    {
        "country": "Easter Island",
        "code": "+56"
    },
    {
        "country": "Ecuador",
        "code": "+593"
    },
    {
        "country": "Egypt",
        "code": "+20"
    },
    {
        "country": "El Salvador",
        "code": "+503"
    },
    {
        "country": "Ellipso (Mobile Satellite service)",
        "code": "+881 2, +881 3"
    },
    {
        "country": "EMSAT (Mobile Satellite service)",
        "code": "+882 13"
    },
    {
        "country": "Equatorial Guinea",
        "code": "+240"
    },
    {
        "country": "Eritrea",
        "code": "+291"
    },
    {
        "country": "Estonia",
        "code": "+372"
    },
    {
        "country": "Eswatini",
        "code": "+268"
    },
    {
        "country": "Ethiopia",
        "code": "+251"
    },
    {
        "country": "Falkland Islands",
        "code": "+500"
    },
    {
        "country": "Faroe Islands",
        "code": "+298"
    },
    {
        "country": "Fiji",
        "code": "+679"
    },
    {
        "country": "Finland",
        "code": "+358"
    },
    {
        "country": "France",
        "code": "+33"
    },
    {
        "country": "French Antilles",
        "code": "+596"
    },
    {
        "country": "French Guiana",
        "code": "+594"
    },
    {
        "country": "French Polynesia",
        "code": "+689"
    },
    {
        "country": "Gabon",
        "code": "+241"
    },
    {
        "country": "Gambia",
        "code": "+220"
    },
    {
        "country": "Georgia",
        "code": "+995"
    },
    {
        "country": "Germany",
        "code": "+49"
    },
    {
        "country": "Ghana",
        "code": "+233"
    },
    {
        "country": "Gibraltar",
        "code": "+350"
    },
    {
        "country": "Global Mobile Satellite System (GMSS)",
        "code": "+881"
    },
    {
        "country": "Globalstar (Mobile Satellite Service)",
        "code": "+881 8, +881 9"
    },
    {
        "country": "Greece",
        "code": "+30"
    },
    {
        "country": "Greenland",
        "code": "+299"
    },
    {
        "country": "Grenada",
        "code": "+1 473"
    },
    {
        "country": "Guadeloupe",
        "code": "+590"
    },
    {
        "country": "Guam",
        "code": "+1 671"
    },
    {
        "country": "Guatemala",
        "code": "+502"
    },
    {
        "country": "Guernsey",
        "code": "+44 1481, +44 7781,\n+44 7839, +44 7911"
    },
    {
        "country": "Guinea",
        "code": "+224"
    },
    {
        "country": "Guinea-Bissau",
        "code": "+245"
    },
    {
        "country": "Guyana",
        "code": "+592"
    },
    {
        "country": "Haiti",
        "code": "+509"
    },
    {
        "country": "Honduras",
        "code": "+504"
    },
    {
        "country": "Hong Kong",
        "code": "+852"
    },
    {
        "country": "Hungary",
        "code": "+36"
    },
    {
        "country": "Iceland",
        "code": "+354"
    },
    {
        "country": "ICO Global (Mobile Satellite Service)",
        "code": "+881 0, +881 1"
    },
    {
        "country": "India",
        "code": "+91"
    },
    {
        "country": "Indonesia",
        "code": "+62"
    },
    {
        "country": "Inmarsat SNAC",
        "code": "+870"
    },
    {
        "country": "International Freephone Service (UIFN)",
        "code": "+800"
    },
    {
        "country": "International Networks",
        "code": "+882"
    },
    {
        "country": "International Premium Rate Service",
        "code": "+979"
    },
    {
        "country": "International Shared Cost Service (ISCS)",
        "code": "+808"
    },
    {
        "country": "Iran",
        "code": "+98"
    },
    {
        "country": "Iraq",
        "code": "+964"
    },
    {
        "country": "Ireland",
        "code": "+353"
    },
    {
        "country": "Iridium (Mobile Satellite service)",
        "code": "+881 6, +881 7"
    },
    {
        "country": "Isle of Man",
        "code": "+44 1624, +44 7524,\n+44 7624, +44 7924"
    },
    {
        "country": "Israel",
        "code": "+972"
    },
    {
        "country": "Italy",
        "code": "+39"
    },
    {
        "country": "Jamaica",
        "code": "+1 658"
    },
    {
        "country": "Jan Mayen",
        "code": "+47 79"
    },
    {
        "country": "Japan",
        "code": "+81"
    },
    {
        "country": "Jersey",
        "code": "+44 1534"
    },
    {
        "country": "Jordan",
        "code": "+962"
    },
    {
        "country": "Kazakhstan",
        "code": "+7 6"
    },
    {
        "country": "Kenya",
        "code": "+254"
    },
    {
        "country": "Kiribati",
        "code": "+686"
    },
    {
        "country": "Korea, North",
        "code": "+850"
    },
    {
        "country": "Korea, South",
        "code": "+82"
    },
    {
        "country": "Kosovo",
        "code": "+383"
    },
    {
        "country": "Kuwait",
        "code": "+965"
    },
    {
        "country": "Kyrgyzstan",
        "code": "+996"
    },
    {
        "country": "Laos",
        "code": "+856"
    },
    {
        "country": "Latvia",
        "code": "+371"
    },
    {
        "country": "Lebanon",
        "code": "+961"
    },
    {
        "country": "Lesotho",
        "code": "+266"
    },
    {
        "country": "Liberia",
        "code": "+231"
    },
    {
        "country": "Libya",
        "code": "+218"
    },
    {
        "country": "Liechtenstein",
        "code": "+423"
    },
    {
        "country": "Lithuania",
        "code": "+370"
    },
    {
        "country": "Luxembourg",
        "code": "+352"
    },
    {
        "country": "Macau",
        "code": "+853"
    },
    {
        "country": "Madagascar",
        "code": "+261"
    },
    {
        "country": "Malawi",
        "code": "+265"
    },
    {
        "country": "Malaysia",
        "code": "+60"
    },
    {
        "country": "Maldives",
        "code": "+960"
    },
    {
        "country": "Mali",
        "code": "+223"
    },
    {
        "country": "Malta",
        "code": "+356"
    },
    {
        "country": "Marshall Islands",
        "code": "+692"
    },
    {
        "country": "Martinique",
        "code": "+596"
    },
    {
        "country": "Mauritania",
        "code": "+222"
    },
    {
        "country": "Mauritius",
        "code": "+230"
    },
    {
        "country": "Mayotte",
        "code": "+262 269, +262 639"
    },
    {
        "country": "Mexico",
        "code": "+52"
    },
    {
        "country": "Micronesia, Federated States of",
        "code": "+691"
    },
    {
        "country": "Midway Island, USA",
        "code": "+1 808"
    },
    {
        "country": "Moldova",
        "code": "+373"
    },
    {
        "country": "Monaco",
        "code": "+377"
    },
    {
        "country": "Mongolia",
        "code": "+976"
    },
    {
        "country": "Montenegro",
        "code": "+382"
    },
    {
        "country": "Montserrat",
        "code": "+1 664"
    },
    {
        "country": "Morocco",
        "code": "+212"
    },
    {
        "country": "Mozambique",
        "code": "+258"
    },
    {
        "country": "Myanmar",
        "code": "+95"
    },
    {
        "country": "Artsakh",
        "code": "+374 47, +374 97"
    },
    {
        "country": "Namibia",
        "code": "+264"
    },
    {
        "country": "Nauru",
        "code": "+674"
    },
    {
        "country": "Nepal",
        "code": "+977"
    },
    {
        "country": "Netherlands",
        "code": "+31"
    },
    {
        "country": "Nevis",
        "code": "+1 869"
    },
    {
        "country": "New Caledonia",
        "code": "+687"
    },
    {
        "country": "New Zealand",
        "code": "+64"
    },
    {
        "country": "Nicaragua",
        "code": "+505"
    },
    {
        "country": "Niger",
        "code": "+227"
    },
    {
        "country": "Nigeria",
        "code": "+234"
    },
    {
        "country": "Niue",
        "code": "+683"
    },
    {
        "country": "Norfolk Island",
        "code": "+672 3"
    },
    {
        "country": "North Macedonia",
        "code": "+389"
    },
    {
        "country": "Northern Cyprus",
        "code": "+90 392"
    },
    {
        "country": "Northern Ireland",
        "code": "+44 28"
    },
    {
        "country": "Northern Mariana Islands",
        "code": "+1 670"
    },
    {
        "country": "Norway",
        "code": "+47"
    },
    {
        "country": "Oman",
        "code": "+968"
    },
    {
        "country": "Pakistan",
        "code": "+92"
    },
    {
        "country": "Palau",
        "code": "+680"
    },
    {
        "country": "Palestine, State of",
        "code": "+970"
    },
    {
        "country": "Panama",
        "code": "+507"
    },
    {
        "country": "Papua New Guinea",
        "code": "+675"
    },
    {
        "country": "Paraguay",
        "code": "+595"
    },
    {
        "country": "Peru",
        "code": "+51"
    },
    {
        "country": "Philippines",
        "code": "+63"
    },
    {
        "country": "Pitcairn Islands",
        "code": "+64"
    },
    {
        "country": "Poland",
        "code": "+48"
    },
    {
        "country": "Portugal",
        "code": "+351"
    },
    {
        "country": "Puerto Rico",
        "code": "+1 787"
    },
    {
        "country": "Qatar",
        "code": "+974"
    },
    {
        "country": "Réunion",
        "code": "+262"
    },
    {
        "country": "Romania",
        "code": "+40"
    },
    {
        "country": "Russia",
        "code": "+7"
    },
    {
        "country": "Rwanda",
        "code": "+250"
    },
    {
        "country": "Saba",
        "code": "+599 4"
    },
    {
        "country": "Saint Barthélemy",
        "code": "+590"
    },
    {
        "country": "Saint Helena",
        "code": "+290"
    },
    {
        "country": "Saint Kitts and Nevis",
        "code": "+1 869"
    },
    {
        "country": "Saint Lucia",
        "code": "+1 758"
    },
    {
        "country": "Saint Martin (France)",
        "code": "+590"
    },
    {
        "country": "Saint Pierre and Miquelon",
        "code": "+508"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "code": "+1 784"
    },
    {
        "country": "Samoa",
        "code": "+685"
    },
    {
        "country": "San Marino",
        "code": "+378"
    },
    {
        "country": "São Tomé and Príncipe",
        "code": "+239"
    },
    {
        "country": "Saudi Arabia",
        "code": "+966"
    },
    {
        "country": "Senegal",
        "code": "+221"
    },
    {
        "country": "Serbia",
        "code": "+381"
    },
    {
        "country": "Seychelles",
        "code": "+248"
    },
    {
        "country": "Sierra Leone",
        "code": "+232"
    },
    {
        "country": "Singapore",
        "code": "+65"
    },
    {
        "country": "Sint Eustatius",
        "code": "+599 3"
    },
    {
        "country": "Sint Maarten (Netherlands)",
        "code": "+1 721"
    },
    {
        "country": "Slovakia",
        "code": "+421"
    },
    {
        "country": "Slovenia",
        "code": "+386"
    },
    {
        "country": "Solomon Islands",
        "code": "+677"
    },
    {
        "country": "Somalia",
        "code": "+252"
    },
    {
        "country": "South Africa",
        "code": "+27"
    },
    {
        "country": "South Georgia and the South Sandwich Islands",
        "code": "+500"
    },
    {
        "country": "South Ossetia",
        "code": "+995 34"
    },
    {
        "country": "South Sudan",
        "code": "+211"
    },
    {
        "country": "Spain",
        "code": "+34"
    },
    {
        "country": "Sri Lanka",
        "code": "+94"
    },
    {
        "country": "Sudan",
        "code": "+249"
    },
    {
        "country": "Suriname",
        "code": "+597"
    },
    {
        "country": "Svalbard",
        "code": "+47 79"
    },
    {
        "country": "Sweden",
        "code": "+46"
    },
    {
        "country": "Switzerland",
        "code": "+41"
    },
    {
        "country": "Syria",
        "code": "+963"
    },
    {
        "country": "Taiwan",
        "code": "+886"
    },
    {
        "country": "Tajikistan",
        "code": "+992"
    },
    {
        "country": "Tanzania",
        "code": "+255"
    },
    {
        "country": "Telecommunications for Disaster Relief by OCHA",
        "code": "+888"
    },
    {
        "country": "Thailand",
        "code": "+66"
    },
    {
        "country": "Thuraya (Mobile Satellite service)",
        "code": "+882 16"
    },
    {
        "country": "East Timor (Timor-Leste)",
        "code": "+670"
    },
    {
        "country": "Togo",
        "code": "+228"
    },
    {
        "country": "Tokelau",
        "code": "+690"
    },
    {
        "country": "Tonga",
        "code": "+676"
    },
    {
        "country": "Transnistria",
        "code": "+373 2, +373 5"
    },
    {
        "country": "Trinidad and Tobago",
        "code": "+1 868"
    },
    {
        "country": "Tristan da Cunha",
        "code": "+290 8"
    },
    {
        "country": "Tunisia",
        "code": "+216"
    },
    {
        "country": "Turkey",
        "code": "+90"
    },
    {
        "country": "Turkmenistan",
        "code": "+993"
    },
    {
        "country": "Turks and Caicos Islands",
        "code": "+1 649"
    },
    {
        "country": "Tuvalu",
        "code": "+688"
    },
    {
        "country": "Uganda",
        "code": "+256"
    },
    {
        "country": "Ukraine",
        "code": "+380"
    },
    {
        "country": "United Arab Emirates",
        "code": "+971"
    },
    {
        "country": "United Kingdom",
        "code": "+44"
    },
    {
        "country": "United States",
        "code": "+1"
    },
    {
        "country": "Universal Personal Telecommunications (UPT)",
        "code": "+878"
    },
    {
        "country": "Uruguay",
        "code": "+598"
    },
    {
        "country": "US Virgin Islands",
        "code": "+1 340"
    },
    {
        "country": "Uzbekistan",
        "code": "+998"
    },
    {
        "country": "Vanuatu",
        "code": "+678"
    },
    {
        "country": " Vatican City State (Holy See)",
        "code": "+39 06 698"
    },
    {
        "country": "Venezuela",
        "code": "+58"
    },
    {
        "country": "Vietnam",
        "code": "+84"
    },
    {
        "country": "Wake Island, USA",
        "code": "+1 808"
    },
    {
        "country": "Wallis and Futuna",
        "code": "+681"
    },
    {
        "country": "Yemen",
        "code": "+967"
    },
    {
        "country": "Zambia",
        "code": "+260"
    },
    {
        "country": "Zanzibar",
        "code": "+255 24"
    },
    {
        "country": "Zimbabwe",
        "code": "+263"
    }

];
export default countryList;
