import * as actions from "../actionTypes/index";

const initialState = {
  userDetails: null,
  selectedUser:null,
  isLoading: false,
  hcp:null,
  hcpList:null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_USER_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: action.payload.user.content,
      };


      case actions.FETCH_HCP_START:
        return {
          ...state,
          isLoading: true,
        };
      case actions.FETCH_HCP_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case actions.FETCH_HCP_SUCCESS:
        return {
          ...state,
          isLoading: false,
          hcp: action.payload,
        };

        case actions.FETCH_HCP_LIST_START:
          return {
            ...state,
            isLoading: true,
          };
        case actions.FETCH_HCP_LIST_FAILED:
          return {
            ...state,
            isLoading: false,
          };
        case actions.FETCH_HCP_LIST_SUCCESS:
          return {
            ...state,
            isLoading: false,
            hcpList: action.payload.hcpList.content,
          };

    default:
      return state;
  }
};
