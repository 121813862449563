import React from "react";
import {NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom'

import overview from "../../Assets/Sidebar-Icons/overview.svg";
// import patterns from "../../Assets/Sidebar-Icons/patterns.svg";
// import trends from "../../Assets/Sidebar-Icons/trends.svg";
// import overlays from "../../Assets/Sidebar-Icons/overlays.svg";
import history from "../../Assets/Sidebar-Icons/history.svg";
import compare from "../../Assets/Sidebar-Icons/compare.svg";
import statistics from "../../Assets/Sidebar-Icons/statistics.svg";
// import Agp from "../../Assets/Sidebar-Icons/Agp.svg";

import AssesmentReport from "../Assesment/AssesmentModal";
const Sidebar = () => {
    const location = useLocation();

  return (

    <nav className="navbar sticky-top navbar-expand navbar-primary flex-md-column flex-row align-items-start py-2 px-2 mw-100">
    <div className="collapse navbar-collapse align-items-start w-100">
        <ul className="flex-md-column flex-row navbar-nav w-100 justify-content-between text-white">
            <li className="nav-item">
                <NavLink to="/Overview"  className={location.pathname==="/Overview" || location.pathname==="/dashboard"?"is-active nav-link  px-2" : "nav-link  px-2"} ><img src={overview} size="20px" alt="overview"/> <span className="d-none d-md-inline  ml-3">Overview</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/History" className={location.pathname==="/History"?"is-active nav-link  px-2" : "nav-link  px-2"} ><img src={history} size="20px" alt="History"/><span className="d-none d-md-inline ml-3">History</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/hcp-link" className={location.pathname==="/hcp"?"is-active nav-link  px-2" : "nav-link  px-2"} ><img src={statistics} size="20px" alt="HCP Link"/><span className="d-none d-md-inline ml-3">HCP Link</span></NavLink>
            </li>
            <li className="nav-item">
                <NavLink to="/hcp-list" className={location.pathname==="/hcp-list"?"is-active nav-link  px-2" : "nav-link  px-2"} ><img src={compare} size="20px" alt="HCP Management"/><span className="d-none d-md-inline ml-3">HCP Management</span></NavLink>
            </li>

            <li className="nav-item">
            <AssesmentReport/>
            </li>
        </ul>
    </div>
</nav>
  );
};

export default Sidebar;
