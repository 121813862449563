import * as actions from "../actionTypes";
import {
  API_BASE_URL,
  loginURL,


} from "../../utils/urls";
import http from "../../utils/http-service";
import { fetchUser } from "./user";
// import {encrypt,decrypt} from "../../utils/encryption";
import ShowToast from "../../utils/show-toast";
import crypto from "crypto";



const loginStart = () => {
  return { type: actions.LOGIN_START };
};

const loginFailed = () => {
  return { type: actions.LOGIN_FAILED };
};

export const loginSuccess = (payload) => {
  return { type: actions.LOGIN_SUCCESS, payload };
};

export const login = (data) => async (dispatch) => {

  try {
    dispatch(loginStart());
    const { email, password } = data;
    let hash = crypto.createHash('sha256');//passing the data to be hashed
let pass_charset = hash.update(password, 'utf-8');//Creating the hash in the required format
let hash_pass= pass_charset.digest('hex');//Printing the output on the console

    let loginData = {
      email,
      // password
      password:hash_pass
      // app:"web"
    };



    // loginData = JSON.stringify(loginData);
    // loginData = encrypt(loginData);
    const result = await http.post(`${API_BASE_URL}${loginURL}`, loginData);

    if (result.status === 200 && result.data.code !== 404 && result.data.code !== 400 && result.data.code !== 401) {
      // // // console.log("Result :", result);
      let data = result.data;

      let token = data.content.token;
      localStorage.setItem("AidexAuthToken", token);
      dispatch(loginSuccess(data));
      dispatch(fetchUser());
    } else {
      ShowToast("Can you please check your email or password","error");
    }

    return result;
  } catch (error) {
    ShowToast(error.response.data.message,"error");

    dispatch(loginFailed());
    throw error;
  }
};
export const userloggedout=()=>{
    return { type: actions.USER_LOGGED_OUT };
}

export const logout = () =>(dispatch)=> {

  localStorage.removeItem("AidexAuthToken");
  dispatch(userloggedout());
  return true;
};

const registerStart = () => {
  return { type: actions.REGISTER_START };
};

const registerFailed = () => {
  return { type: actions.REGISTER_FAILED };
};

const registerSuccess = (payload) => {
  return { type: actions.REGISTER_SUCCESS, payload };
};

export const register = (data) => async (dispatch) => {
  try {
    dispatch(registerStart());
    // let registerData={...data}

    // registerData=JSON.stringify(registerData)
    // registerData = encrypt(registerData);
    const result = await http.post(`${API_BASE_URL}${loginURL}`, data);

    if (result.status === 200) {
      const {
        data: { data },
      } = result;


      delete data.token;
      dispatch(registerSuccess(data));
      // dispatch(fetchUser());
    }

    return result;
  } catch (error) {

    dispatch(registerFailed());
    throw error;
  }
};

const forgotPasswordStart = () => {
  return { type: actions.FORGOT_PASSWORD_START };
};

const forgotPasswordFailed = () => {
  return { type: actions.FORGOT_PASSWORD_FAILED };
};

// const forgotPasswordSuccess = (payload) => {
//   return { type: actions.FORGOT_PASSWORD_SUCCESS, payload };
// };

export const forgotPassword = (data) => async (dispatch) => {
  try {
    dispatch(forgotPasswordStart());
    // let forgetData=data
    // forgetData=JSON.stringify(forgetData)
    // forgetData=encrypt(forgetData)
    const result = await http.post(`${API_BASE_URL}${loginURL}`, data);

    return result;
  } catch (error) {

    dispatch(forgotPasswordFailed());
    throw error;
  }
};

const resetPasswordStart = () => {
  return { type: actions.RESET_PASSWORD_START };
};

const resetPasswordFailed = () => {
  return { type: actions.RESET_PASSWORD_FAILED };
};

// const resetPasswordSuccess = (payload) => {
//   return { type: actions.RESET_PASSWORD_SUCCESS, payload };
// };

export const resetPassword = (data) => async (dispatch) => {
  try {
    dispatch(resetPasswordStart());
    // let resetData=data
    // resetData=JSON.stringify(resetData)
    // resetData=encrypt(resetData)

    const result = await http.post(`${API_BASE_URL}${loginURL}`, data);


    return result;
  } catch (error) {
    dispatch(resetPasswordFailed());
    throw error;
  }
};

const resendVerificationEmailStart = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_START };
};

const resendVerificationEmailFailed = () => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_FAILED };
};

const resendVerificationEmailSuccess = (payload) => {
  return { type: actions.RESEND_VERIFICATION_EMAIL_SUCCESS, payload };
};

export const resendVerificationEmail = (data) => async (dispatch) => {
  try {
    dispatch(resendVerificationEmailStart());
    // let resendEmailData=data
    // resendEmailData=JSON.stringify(resendEmailData)
    // resendEmailData=encrypt(resendEmailData)
    const result = await http.post(
      `${API_BASE_URL}${loginURL}`,
      data
    );

    if (result.status === 200) {
      const {
        data: { data },
      } = result;
      // data=decrypt(data);
      // data=JSON.parse(data);
      dispatch(resendVerificationEmailSuccess(data));
    }

    return result;
  } catch (error) {
    dispatch(resendVerificationEmailFailed());
    throw error;
  }
};
export const accountActivation = (token) => async (dispatch) => {
  let result;
  try {

    // let resetData=data
    // resetData=JSON.stringify(resetData)
    // resetData=encrypt(resetData)

    result = await http.post(`${API_BASE_URL}${loginURL}?token=${token}`);


    ShowToast(result.data.message,"success");
    return result;

  } catch (error) {

    dispatch(resetPasswordFailed());
    if( error.response.status===409){
      ShowToast(error.response.data.message,"success");
    }
    else{
      ShowToast(error.response.data.message,"error");
    }
    throw error;
    //  return error.response;
  }
};
