// import * as actions from "../actionTypes";
import {
  API_BASE_URL,
  FEEDBACK_URL,


} from "../../utils/urls";
import http from "../../utils/http-service";
import ShowToast from "../../utils/show-toast";



// const feedbackStart = () => {
//   return { type: actions.FEEDBACK_START };
// };
//
// const feedbackFailed = () => {
//   return { type: actions.FEEDBACK_FAILED };
// };
//
// export const feedbackSuccess = (payload) => {
//   return { type: actions.FEEDBACK_SUCCESS, payload };
// };

export const sendFeedback = (data) => async (dispatch) => {

  try {
    const { message } = data;




    const result = await http.post(`${API_BASE_URL}${FEEDBACK_URL}`, {
      message
    });
    return result;
  } catch (error) {
    ShowToast(error.response.data.message,"error");

    throw error;
  }
};
