import React from "react";
import {useSelector} from "react-redux";
import TimeInRange from "../Charts/TimeInRange";

const TimeInRangeReport = (props) => {
  const {user} = useSelector((state)=>{return state});
  return (
    <div className='h-100 d-flex flex-column justify-content-between'>

    <div className="chart-heading mt-3  loginNew-HCP-panel">
      <span className="bg-common btn px-2 py-2 font-weight-bold text-white"
      style={{border: "none",borderRadius: "0" ,cursor:"unset"}}>
        Time in Range
      </span>
    </div>




  <div className="row justify-content-between px-3 mb-4">
    <div className="col-md-9 col-12 ">
     <TimeInRange chartId={props.chartId} data={props.data}/>
    </div>

      <div className="col-md-3 col-12 mt-md-0 mt-3">


    <div className="chart-heading text-center loginNew-HCP-panel">
      <span className="bg-common px-2  py-2 font-weight-bold text-white">
        Target Range
      </span>
    </div>

    <div className='mt-4 text-md-start text-center '>
    <small className=" text-black font-weight-bold">{user && user.userDetails && user.userDetails.low? user.userDetails.low:null}-{user && user.userDetails && user.userDetails.high? user.userDetails.high:null} mmol/L</small><br/>


    </div>
    </div>
    </div>

  </div>

  );
};

export default TimeInRangeReport;
