import React from "react";

// import {Pattern} from "@amcharts/amcharts4/core";
import { useSelector} from "react-redux";

// import {DatePicker} from "antd";
// import { default as Titles } from "../utils/document-titles";
// import Loading from '../Components/Common/Loading';


const AssesmentReport = () => {
  // document.title = Titles.reports;
  // const { RangePicker } = DatePicker;
  const assesmentUrl = useSelector((state) => {
    if(state.cgm && state.cgm.assesmentUrl){
      return state.cgm.assesmentUrl;
    }});
// const testFunc =(e)=>{
//   // // console.log("E", e);
//   // // console.log("target", e.target.document);
//
// }

    return (

        <div className="container-fluid h-100 p-0">
            {/*<Navbar user={userData}/>*/}
            <div className="row h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">
            {/*<iframe src={assesmentUrl ? assesmentUrl:null} onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));' onLoad={(e)=>{
              testFunc(e)
            }} width="100%" title="description"></iframe>*/}
            <iframe src={assesmentUrl ? assesmentUrl:null} width="100%" title="description"></iframe>

            </div>
        </div>
    );
};

export default AssesmentReport;
