export const DefaultTitle = "AIDEX";

export default {
  default: `${DefaultTitle} | Welcome`,
  login: `${DefaultTitle} | Login`,
  // register: `${DefaultTitle} | Register`,
  // dashboard: `${DefaultTitle} | Dashboard`,
  history: `${DefaultTitle} | History`,
  reports: `${DefaultTitle} | Reports`,
  hcp: `${DefaultTitle} | HCP Link`,

  // emergency: `${DefaultTitle} | Emergency`,
  // forgotPassword: `${DefaultTitle} | Forgot Password`,
  // resendVerificationEmail: `${DefaultTitle}  | Resend Verification Email`,
};
