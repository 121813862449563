import React from "react";
import ReportsPage from "../../Pages/ReportsPage";
import DocumentTitles from "../../utils/document-titles";
export default function Dashboard() {
  document.title = DocumentTitles.dashboard;



return (
  <div style={{height:"100%"}}>

    <ReportsPage/>


  </div>
);
}
