import React, { useEffect, useState,useRef } from "react";
import { useHistory } from "react-router-dom";
// import countryList from "../auth/CountriesList";
// import ShowToast from "../../utils/show-toast";
import { Spin } from "antd";
import { useDispatch } from "react-redux";
// import { register } from "../../redux/actions/auth";
// import { decrypt } from "../../utils/encryption";
import countryList from "./CountriesList";

// import moment from "moment";
import { fetchHCP, updateHCP} from "../../../redux/actions/user";


export const useMountEffect = (fun) => useEffect(fun, []);

// Gneral Focus Hook
const UseFocus = () => {
	const htmlElRef = useRef(null)
	const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

	return [ htmlElRef,  setFocus ]
}

const HcpLink = () => {
  // const data = useSelector((state) => state.user.userDetails);

  const history = useHistory();
  const [input1Ref, setInput1Focus] = UseFocus();

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [gender, setGender] = useState("");
  const [dob, setDob] = useState();
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  const [address, setAddress] = useState("");
  const [town, setTown] = useState("");
  const [postCode, setPostCode] = useState("");
  const [countryCode, setCountryCode] = useState("");

  // const [country, setCountry] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [authCode, setAuthCode] = useState("");


  // const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

useEffect(() => {
fetchingHCP();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
// useEffect(() => {
//   // // // console.log("DATE UPDATE",dob);
// },[dob])

const fetchingHCP=async()=>{
  let result = await dispatch(fetchHCP());
  // // // console.log("RESPONSE IN HCP comp", result);
  if(result.status === 200) {
    let data = result.data.content;
    setFirstName(data.name);
    let dOb = new Date(data.date_of_birth);
    // dOb = JSON.stringify(dOb);
    // // // console.log("TYPE OF", typeof(dOb));
    // dOb = moment(dOb).format("DD-MM-YYYY");
    // // // // console.log("TYPE OF", typeof(dOb));
    // dOb = moment(dOb).format("DD-MM-YYYY");
    // let dOb = new Date();
    dOb.setDate(dOb.getDate());
    let date = dOb.toISOString().substr(0,10);
    // // // console.log("DATE", date);
    // date = moment(date).format("DD-MM-YYYY");
    // // // console.log("DATE AFTER :",date);
    setDob(date);
    // // // console.log("dOb",dOb);
    setPhoneNo(data.phone_no);
    setAddress(data.address_line_1);
    setTown(data.address_line_2);
    setPostCode(data.postal_code);
    setCountryCode(data.country_code);
    updateFormat();

  }
}
const updateFormat=()=>{
  // let element = document.getElementById('dob');
  // // // console.log("ELEMENT", element);
    // element.setAttribute(
    //     "data-date",
    //     moment(element.value, "YYYY-MM-DD")
    //     .format( this.getAttribute("data-date-format") )
    // )

}

  const handleUserUpdate = async (e) => {
    e.preventDefault();

    // const dta={
    //   connection_code: authCode,
    //   name:firstName,
		//
    //   date_of_birth:dob,
		//
    //   address_line_1:address,
    //   address_line_2:town,
    //   postal_code:postCode,
    //   country_code:"+44",
    //   phone_no:phoneNo
    // }

    // let dateOfBirth = new Date(dob);
    // let postcode = postCode;
    try {
      setIsLoading(true);

      let result = await dispatch(
        updateHCP({
          connection_code: authCode,
          name:firstName,

          date_of_birth:dob,

          address_line_1:address,
          address_line_2:town,
          postal_code:postCode,
          country_code:countryCode,
          phone_no:phoneNo
        })
      );
      setIsLoading(false);

      if(result.data.code !== 400 && result.data.code === 200){
				history.push("/");
      }

      // ShowToast(result.data.message, "success");
    } catch (err) {
      ////(err.response)

      // ShowToast(err.response.data.message, "error");
      setIsLoading(false);
    }
  };
  const validate=(val)=> {
  let v1 = document.getElementById("name");


  let v2 = document.getElementById("authorisation");
  let v3 = document.getElementById("dob");
  let v4 = document.getElementById("mob");
  let v5 = document.getElementById("address_line_1");
  let v6 = document.getElementById("address_line_2");
  let v7 = document.getElementById("postalcode");
  let v8 = document.getElementById("countrycode");
  // let v9 = document.getElementById("countrycode");



  // let v6 = document.getElementById("ans");

  let flag1 = true;
  let flag2 = true;
  let flag3 = true;
  let flag4 = true;
  let flag5 = true;
  let flag6 = true;
  let flag7 = true;
  let flag8 = true;
  // let flag9 = true;



  if(val===1) {
  if(v1.value === "") {
  v1.style.borderColor = "#b78181";
  flag1 = false;
  }
  else {
  v1.style.borderColor = "#1f3c90";
  flag1 = true;
  }
  }

  if(val===2) {
  if(v2.value === "") {
  v2.style.borderColor = "#b78181";
  flag2 = false;
  }
  else {
  v2.style.borderColor = "#1f3c90";
  flag2 = true;
  }
  }
  if(val===3) {
  if(v3.value === "") {
  v3.style.borderColor = "#b78181";
  flag3 = false;
  }
  else {
  v3.style.borderColor = "#1f3c90";
  flag3 = true;
  }
  }
  if(val===4) {
  if(v4.value === "") {
  v4.style.borderColor = "#b78181";
  flag4 = false;
  }
  else {
  v4.style.borderColor = "#1f3c90";
  flag4 = true;
  }
  }
  if(val===5) {
  if(v5.value === "") {
  v5.style.borderColor = "#b78181";
  flag5 = false;
  }
  else {
  v5.style.borderColor = "#1f3c90";
  flag5 = true;
  }
  }
  if(val===6) {
  if(v6.value === "") {
  v6.style.borderColor = "#b78181";
  flag6 = false;
  }
  else {
  v6.style.borderColor = "#1f3c90";
  flag6 = true;
  }
  }
  if(val===7) {
  if(v7.value === "") {
  v7.style.borderColor = "#b78181";
  flag7 = false;
  }
  else {
  v7.style.borderColor = "#1f3c90";
  flag7 = true;
  }
  }
  if(val===8) {
  if(v8.value === "") {
  v8.style.borderColor = "#b78181";
  flag8 = false;
  }
  else {
  v8.style.borderColor = "#1f3c90";
  flag8 = true;
  }
  }

  let flag = flag1 && flag2 && flag3 && flag4 && flag5 && flag6 && flag7 && flag8;

  return flag;
  }


  useMountEffect( setInput1Focus );

  return (
    <section className="row mw-100 ml-auto mr-auto">

<div className="col-12 col-md-9 m-auto">
<form className="form-card" onSubmit={handleUserUpdate}>
<div className="card">
<div className="row justify-content-center pb-4 pt-2 text-left">
<div className="col-12"><h4>HCP Authorisation Code</h4></div>
<div className="form-group col-11 m-auto flex-column d-flex">
<input
required
  // disabled={editMode ? false : true}
  defaultValue={authCode}
  onChange={(e) => setAuthCode(e.target.value)}
  type="text"
  id="authorisation" name="authorisation" placeholder="Enter HCP Authorisation code" onBlur={()=>validate(2)}
  className="registration-form-control auth-input"
  ref={input1Ref}
/>
</div>
</div>

</div>
              <div className="card">
                                  <div className="row justify-content-center pb-4 pt-2 text-left">
                                  <div className="col-12"><h4>Your Details</h4></div>

                                      <div className="form-group col-sm-5 flex-column d-flex"> <label className="form-control-label">Name<span className="text-danger"> *</span></label>
                                      <input
                                      required
                                        // disabled={editMode ? false : true}
                                        defaultValue={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Enter your name"
                                        onBlur={()=>validate(1)}
                                        className="registration-form-control"


                                      />
                                      </div>
                                      <div className="form-group col-sm-5 flex-column d-flex"> <label className="form-control-label">Select your date of birth<span className="text-danger"> *</span></label>
                                       <input
                                       required
                                         // disabled={editMode ? false : true}
                                         defaultValue={dob}
                                         onChange={(e) => setDob(e.target.value)}
                                         type="date"
                                         id="dob" name="dob"
                                         onBlur={()=>validate(3)}
                                         className="registration-datePicker"
                                         style={{maxHeight:"45px"}}



                                       />
                                       </div>

                                  </div>
                                  <div className="row justify-content-center pb-4 pt-2 text-left">

                                      <div className="form-group col-sm-5 flex-column d-flex"> <label className="form-control-label">Phone number<span className="text-danger"> *</span></label>
                                      <input
                                      required
                                        // disabled={editMode ? false : true}
                                        defaultValue={phoneNo}
                                        onChange={(e) => setPhoneNo(e.target.value)}
                                        type="number"
                                        className="registration-form-control"
                                        id="mob" name="mob"
                                        placeholder=""
                                        onBlur={()=>validate(4)}

                                      />
                                       </div>
                                       <div className="form-group col-sm-5 flex-column d-flex">
                                       <label className="form-control-label">Address Line 1 <span className="text-danger"> *</span></label>
                                       <input
                                       required
                                         // disabled={editMode ? false : true}
                                         defaultValue={address}
                                         onChange={(e) => setAddress(e.target.value)}
                                         type="text"
                                         className="registration-form-control"
                                         id="address_line_1" name="address_line_1" placeholder="" onBlur={()=>validate(5)}


                                       />
                                       </div>
                                  </div>
                                  <div className="row justify-content-center pb-4 pt-2 text-left">

                                      <div className="form-group col-sm-5 flex-column d-flex">
                                      <label className="form-control-label">Address Line 2 <span className="text-danger"> *</span></label>
                                      <input
                                      required
                                      // disabled={editMode ? false : true}
                                      defaultValue={town}
                                      onChange={(e) => setTown(e.target.value)}
                                        type="text"
                                        className="registration-form-control"
                                        id="address_line_2" name="address_line_2" placeholder="" onBlur={()=>validate(6)}


                                      />
                                      </div>
                                      <div className="form-group col-sm-5 flex-column d-flex">
                                      <label className="form-control-label">Postal Code<span className="text-danger"> *</span></label>
                                      <input
                                      required
                                        // disabled={editMode ? false : true}
                                        defaultValue={postCode}
                                        onChange={(e) => setPostCode(e.target.value)}
                                        type="text"
                                        className="registration-form-control"
                                        id="postalcode" name="postalcode" placeholder="" onBlur={()=>validate(7)}

                                      />
                                       </div>
                                  </div>
                                  <div className="row justify-content-center pb-4 pt-2 text-left">

                                       <div className="form-group col-sm-5 flex-column d-flex m-auto">
                                       <label className="form-control-label">Country<span className="text-danger"> *</span></label>

                                       <select
                                       // required
                                        // disabled={editMode ? false : true}

                                        value={countryCode}
                                        onChange={(e) => setCountryCode(e.target.value)}
                                        placeholder="Select Your Country"
                                        className="registration-form-control "
                                        id="countrycode"
																				name="countrycode"

																				onBlur={()=>validate(8)}
                                        >
                                          <option value="">Select Country</option>
                                          {countryList.map((option, index) => (
                                            <option key={index} value={option.code}>{option.country}</option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>

                          </div>
                          <div className="row justify-content-center">
                              {/*<div className="form-group col-sm-5 flex-column d-flex">
                              <button
                               className={editMode?"text-primary registration-btn btn-primary":"registration-btn btn-primary"}
                               type="button"
                                onClick={() => {
                                  setEditMode(!editMode);
                                }}
                              >
                                Edit Profile?
                              </button>
                              </div>*/}
                              <div className="form-group col-sm-6 flex-column d-flex">
                              <Spin spinning={isLoading}>
                                <button
                                  type="Submit"
                                  className={`registration-btn`}
                                >
                                  Submit
                                </button>
                              </Spin>
                              </div>
                          </div>
                          </form>

                          </div>

    </section>

  );
};

export default HcpLink;
