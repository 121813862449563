import React, { useEffect, useState} from "react";
// import { useHistory } from "react-router-dom";
// import countryList from "../auth/CountriesList";
// import ShowToast from "../../utils/show-toast";
// import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { register } from "../../redux/actions/auth";
// import { decrypt } from "../../utils/encryption";
// import countryList from "./CountriesList";

// import moment from "moment";
import { fetchHCPList} from "../../../redux/actions/user";


export const useMountEffect = (fun) => useEffect(fun, []);

// Gneral Focus Hook


const HcpManagement = () => {
  // const data = useSelector((state) => state.user.userDetails);

  // const history = useHistory();
  // const [input1Ref, setInput1Focus] = UseFocus();
  const list = useSelector((state) => {return (state.user.hcpList)});


  // const [isLoading, setIsLoading] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [instance, setInstance] = useState({});





  // const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();

useEffect(() => {
fetchingHCP();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[])
// useEffect(() => {
//   // // // console.log("DATE UPDATE",dob);
// },[dob])

const fetchingHCP=async()=>{
  let result = await dispatch(fetchHCPList());
  // // // console.log("RESPONSE IN HCP comp", result);
  if(result.status === 200) {
    // let data = result.data.content;
    // console.log("Data Comp:", data);
  }
}



  return (
    <section className="row mw-100 ml-auto mr-auto">



    <div className="col-12">
                        <div className="row justify-content-between pl-3">

                            <div className="col-12 col-md-6">
                              <h1 className="pt-3 pb-3 font-weight-bold chart-heading">HCP Management</h1>
                            </div>
                            {/*<div className="col-12 col-md-6 text-right">
                              <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add Clinic Admin</button>
                            </div>
                            <div className="col-12 col-md-6 text-right">
                              <button className="btn btn-primary mt-3" onClick={()=>{setAddModal(true)}}>Add Clinician</button>
                            </div>*/}
                            <div className="col-12">
                            <table className="table table-hover" id="task-table">
                              <thead>
                              <tr>
                                <th>Name</th>
                                <th>Address</th>
                                {/*<th>Address 2</th>

                                <th>Address 3</th>*/}
                                <th>Town</th>
                                <th>County</th>

                                <th>Country</th>
                                <th>Postcode</th>
                                <th>Phone</th>
                                <th>Mobile</th>
                                <th>HCP Contact Name</th>
                                <th>Website</th>





                                <th>Actions</th>
                              </tr>
                              </thead>
                              <tbody>

                              { list !== null && list.length > 0 && list.map((row, index)=>(
                              <tr>
                                <td>{row.name}</td>
                                <td>{row.addressLine1}</td>
                                {/*<td>{row.addressLine2}</td>
                                <td>{row.addressLine3}</td>*/}
                                <td>{row.town}</td>
                                <td>{row.county}</td>
                                <td>{row.country}</td>
                                <td>{row.postcode}</td>
                                <td>{row.telephoneNumber}</td>
                                <td>{row.telephoneNumber2}</td>
                                <td>{row.hcpContactName}</td>
                                <td>{row.website}</td>


                                <td>


                                  <button
                                      type="button"
                                      className=" ml-2 bg-white"
                                      onClick={()=>{
                                        // setToDelete(`${row.pId}`);
                                        setInstance(row)
                                        setDelModal(true);
                                    }}
                                  >
                                                      <span className="">
                                                      <i className="fas fa-trash-alt text-danger"></i>
                                                      </span>
                                  </button>



                                </td>




                              </tr>
                            ))}

                              </tbody>
                            </table>
                            </div>


                            <div className={delModal? "modal d-block fading" : "modal d-none"}>
                                <div className="modal-dialog modal-dialog-centered modal-confirm">
                                  <div className="modal-content">
                                    <div className="modal-header flex-column">
                                      <div className="icon-box">
                                        <i className="fas fa-trash-alt text-danger"></i>
                                      </div>
                                      <h4 className="modal-heading w-100 text-black">Are you sure?</h4>
                                      <button type="button" className="close" onClick={()=>{setDelModal(false)}} >&times;</button>
                                    </div>
                                    <div className="modal-body">
                                    <p>Do you really want to delete this HCP Link? Type "<strong>{instance.name}</strong>" to confirm deletion.</p>

                                      <input
                                      type="email"
                                      className="form-control"
                                      placeholder="HCP Name"
                                      id="deleteLink"

                                      required
                                    />
                                    </div>
                                    <div className="modal-footer justify-content-center">
                                      <button type="button" className="btn btn-secondary" onClick={()=>{setDelModal(false)}}>Cancel</button>

                                        <button type="button" className="btn btn-danger">Delete</button>

                                    </div>
                                  </div>
                                </div>

                              </div>
                        </div>

                    </div>

    </section>

  );
};

export default HcpManagement;
