import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
// import {fetchExportData} from "../../../redux/actions/export";
import { useSelector, useDispatch } from "react-redux";
// import {encrypt,decrypt} from "../../utils/encryption";
import {fetchExportCgmData} from "../../redux/actions/cgm";


const PDF =  require("../../Assets/pdf.png");

const ExportData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => {
    if(state.user && state.user.userDetails){
      return state.user.userDetails;
    }});
  // const device = useSelector((state) =>(state.devices.devices));
  const { RangePicker } = DatePicker;
  // const [isLoading, setIsLoading] = useState(false);
  // const data = useSelector((state) => state);
  const [endDate, setStartDate] = useState("");
  const [startDate, setEndDate] = useState("");
  // const [repoUrl, setRepoUrl] = useState("");

  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [dropMenu, setDropMenu] = useState(false);
  const [days, setDays] = useState("");

  const [showButton,setShowButton]=useState(false);
  // const [exportableDays,setExportableDays]=useState(false);
  const [allowedToShow, setAllowedToShow]=useState(false);

  const safari = useSelector((state) => {
    return state.auth.safari;
    });


  // useEffect(() => {
  //
  //   if (days !== "" || startDate !== "") {
  //     fetchReadings();
  //   }
  // }, [days, startDate]);
  const fetchReadings = async () => {


    let duration = {};
    if (days !== "") {
      let data_from = findStartDate(days);
      let data_to = findEndDate();
      duration.timeFrom = data_from;
      duration.timeTo = data_to;


      let startRep = data_from;
      startRep = moment(startRep).format("yyyy-MM-DD");
      let endRep = data_to;
      endRep = moment(endRep).format("yyyy-MM-DD");
      let eml = user && user.email ?user.email: null;
      if(eml !== "" && eml !== null){

        eml = eml.replace("@","%40");

      }
      let uuid = user && user.uuid ?user.uuid: null;
      let gender = user && user.gender && user.gender !== null? user.gender: "";


      let nam = user && user.name ?user.name: null;
      let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`

        // setRepoUrl(repUrl);
        duration.assesmentUrl = repUrl;


    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      duration.timeTo = new Date (endDate);


      let startRep = new Date(startDate);
      startRep = moment(startRep).format("yyyy-MM-DD");
      let endRep = new Date (endDate);
      endRep = moment(endRep).format("yyyy-MM-DD");
      let eml = user && user.email ?user.email: null;
      if(eml !== "" && eml !== null){

        eml = eml.replace("@","%40");

      }
      let uuid = user && user.uuid ?user.uuid: null;
      let gender = user && user.gender && user.gender !== null? user.gender: "";


      let nam = user && user.name ?user.name: null;
      let repUrl = `https://report.grxaidex.co.uk/en/aidex?id=${uuid}&device=""&start=${startRep}&end=${endRep}&gender=${gender}&email=${eml}&name=${nam}`

        // setRepoUrl(repUrl);

        duration.assesmentUrl = repUrl;
    }
    // // // console.log("DURATION", duration);
     await dispatch(fetchExportCgmData(duration));
    // // // console.log("REs Expo",response);
    // if(response.data && response.data>0){
      // setOrganizedData(response.data);
      // setCalculatedValues(response.calculation);
      // setCsvButton(true);
    // }

  };
  const fetchReadingsForCsv = async () => {


    let duration = {};
    if (days !== "") {
      let data_from = findStartDate(days);
      let data_to = findEndDate();
      duration.timeFrom = data_from;
      duration.timeTo = data_to;


    }
    if (startDate) {

      duration.timeFrom = new Date(startDate);
      duration.timeTo = new Date (endDate);




    }
    const response = await dispatch(fetchExportCgmData(duration));

    if(response.data && response.data.length>0){
      // setOrganizedData(response.data);
      // setCalculatedValues(response.calculation);
      // setCsvButton(true);
      convert(response.data);

    }
      // const response = await dispatch(fetchExportCgmData(duration));
      // if(response.status === 200){
      //   convert();
      // }
  //   if (rangFilter === false){
  //   let start = findStartDate(filter);
  //
  //   setFromDate(moment(start).format('D MMM YYYY').toString());
  //   setToDate(moment(findStartDate()).format('D MMM YYYY').toString());
  // } else if (rangFilter === true) {
  //
  // }
  };





  const findStartDate = (filter) => {

    // let currentDate = new Date();

    if(filter === "30days") {
      let start = moment().subtract(30, 'days');
      return new Date(start._d);
    }else if(filter==="7days") {

      let start = moment().subtract(7, 'days');

      return new Date(start._d);


    } else if (filter === "14days") {


      let start = moment().subtract(14, 'days');
      return new Date(start._d);

    } else if (filter === "90days") {

      let start = moment().subtract(90, 'days');
      return new Date(start._d);

    } else if(filter === "24hours"){

      let start = moment().add(-24, 'hours');
      return new Date(start._d);

    }
    else if(filter === "12hours"){
      let start = moment().add(-12, 'hours');
      return new Date(start._d);
    }
    else if(filter === "6hours"){
      let start = moment().add(-6, 'hours');
      return new Date(start._d);
    } else if (filter === "today") {

      return new Date();

    } else{

    }

  }
  const findEndDate = () => {
    // let currentDate = new Date();
    return (new Date());
  }

   //Fetch Export Data
  //  useEffect(()=>{
  //   handleExportData()
  //   },[])

  const handleExportData=async()=>{
    // if(exportableDays){
      fetchReadings();
      // let data={time:days,start:startDate,end:endDate}
      //  // data=JSON.stringify(data);
      //  //        data=encrypt(data);
      //         localStorage.setItem("export",data);
              document.getElementById('closebtn').click();

              history.push({
                          pathname:"/export-data",

                        });
    // }

    // //("data=",data)
    // try {
    //   setIsLoading(true);
    //   let response= await dispatch( fetchExportData(data))
    //         setIsLoading(false);
    //         data=JSON.stringify(data)
    //         data=encrypt(data)
    //         localStorage.setItem("export",data)
    //         history.push({
    //           pathname:"/export-data",

    //         });
    //         //("printing")
    // } catch (error) {
    //     setIsLoading(false);

    // }

  }



  //Handle Filters
  const handleDaysFilter = (d) => {
    setDays(d);
    setStartDate("");
    setEndDate("");
    setAllowedToShow(true);
    // if(d === "30days" || d === "90days"){
    //   setExportableDays(false);
    // }else {
    //   setExportableDays(true);
    //
    // }

  };
  //Handle Date Picker
  const handleDateChange = (val) => {
    // setCsvButton(false);

    if (val && val.length > 0) {

      setStartDate(val[1].format("YYYY-MM-DD").toString());
      setEndDate(val[0].format("YYYY-MM-DD").toString());
      // setToDate(val[1].format("YYYY-MM-DD").toString());
      // setFromDate(val[0].format("YYYY-MM-DD").toString());

    }else {
      setStartDate("");
      setEndDate("");
      // setToDate("");
      // setFromDate("");
      setAllowedToShow(false);
    }

  };
  const checkDates = ()=>{

    if(startDate !== "" && endDate !== "") {
      setDays("")
      setAllowedToShow(true)
      // let a = moment(startDate);
      // let b = moment(endDate);
      // let daysDif=b.diff(a, "days");
      // if(daysDif < 15){
      //   setExportableDays(true);
      // }else {
      //   setExportableDays(false);
      //
      // }
    }
  }
  const convert = (dataToConvert) => {
      // e.preventDefault();

      // "\uFEFF"+
    // const response = await  fetchReadings();
    // if (response && response.data && response.data.length>0){
      let dataNew =[];
      dataToConvert && dataToConvert.length>0 && dataToConvert.map((obj)=>{


        let object = {"deviceSn": obj.deviceSn,
         "time": obj.time, "glucose": obj.glucose}

        dataNew.push(object);
        return 0;

      })

      const parsedJson = dataNew;
      if(parsedJson && parsedJson.length <1){
        return;
      }
      if (
        !Array.isArray(parsedJson) ||
        !parsedJson.every((p) => typeof p === "object" && p !== null)
      ) {
        return;
      }
      const heading = Object.keys(parsedJson[0]).join(",");
      const space = "\n"
      const body = parsedJson.map((j) => Object.values(j).join(",")).join("\n");
      // setCsv(`${heading}${space}${body}`);
      let csvData = heading + space + body;

      if(safari){
        download(csvData,"GlucoRx Aidex CGM Record", 'text/csv;charset=utf-8;');

      }else{
      download("\uFEFF"+csvData,"GlucoRx Aidex CGM Record", 'text/csv;charset=utf-8;');
      }

    };

    // Function to download data to a file
  const download=( processedData, filename, type)=> {
    let data = processedData;

      var file = new Blob([data], {type: type});
      if (window.navigator.msSaveOrOpenBlob) // IE10+
          window.navigator.msSaveOrOpenBlob(file, filename);
      else { // Others
          var a = document.createElement("a"),
                  url = URL.createObjectURL(file);
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(function() {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
          }, 0);
      }
  }

  return (
    <div className="row">
      <button
        type="button"
        className="p-0 pdf-btn"
        data-toggle="modal"
        data-target="#exportModal"
        style={{background: "none"}}
          // onClick={()=>history.push({
          //             pathname:"/export-data",
          //
          //           })}
      >
      <img src={PDF} className="pdf-img" style={{width:"25px"}} alt="PDF Icon"/>
      <p className="mt-1">Export</p>

        {/* <i className="fas fa-file-export"></i>*/}
      </button>


      <div
        className="modal fade"
        id="exportModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Export
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="closebtn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="container">
                <div className="d-flex flex-row justify-content-center">
                  <button
                    className={
                      startDate === "" && days === "7days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("7days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);


                    }}
                  >
                    7 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "14days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("14days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    14 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "30days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("30days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    30 Days
                  </button>
                  <button
                    className={
                      startDate === "" && days === "90days"
                        ? "bg-common text-white rounded px-2 mr-2"
                        : " bg-light rounded px-2 mr-2"
                    }
                    onClick={() => {
                      handleDaysFilter("90days");
                      setDropMenu(false);
                      // setCsvButton(false);
                      // setOrganizedData([]);



                    }}
                  >
                    90 Days
                  </button>
                  <button
                    className={
                      startDate !== ""
                        ? "bg-common text-white rounded px-2 dropdown"
                        : "bg-light rounded px-2 dropdown"
                    }
                    onClick={(e) => {
                      setDropMenu(!dropMenu);
                      setAllowedToShow(false);
                      setDays("");
                      // setCsvButton(false);
                      // setOrganizedData([]);




                    }}
                  >
                    Range
                  </button>
                </div>
                <div className="row text-center mt-3 justify-content-center">
                  <div className="col-12">
                    <div className={dropMenu ? " d-block" : " d-none"}>
                      <RangePicker
                        defaultValue={
                          startDate && [moment(startDate), moment(endDate)]
                        }
                        onChange={(val) => {
                          handleDateChange(val);
                          setShowButton(!showButton);
                        }}
                      />
                      <button className="bg-common text-white rounded px-2 ml-3" onClick={()=>{checkDates()}}>Go</button>
                    </div>
                  </div>
                </div>
                <div className='row justify-content-center align-items-center my-5'>
                {allowedToShow?
                  <div className='col-12'>
                     <div className='row justify-content-center pdf-device'>
                     {/*exportableDays?*/}
                        <div className='col-6'>
                       <button className="btnImgColor rounded px-3 mr-2 py-3"
                     onClick={()=>{
                        handleExportData()
                      }}>
                          <span className=''>Export PDF</span>

                      </button>
                        </div>
                      {/*  :null/}*/}
                        <div className='col-6'>
                       <button className= " btnImgColor rounded px-3 mr-2 py-3"
                       // disabled={!csvButton}
                       onClick={()=>fetchReadingsForCsv()}>


                          <span className="">Export CSV</span>
                      </button>
                        </div>

                        {/*<div className='col-3'>
                            <button className={
                           patterns === true
                           ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                           : " btnImgColor rounded px-3 mr-2 py-3"
                         } onClick={()=>{
                             setPatterns(!patterns)
                           }}>
                              <div className="example example-cover row">
                              <div className="col-12">
                              <img src={paterns} size="20px"></img>
                              </div>
                              <div className="col-12">

                               <span className="d-none d-md-inline">Patterns</span>
                               </div>
                             </div>
                           </button>
                        </div>

                        <div className='col-3'>
                           <button className={
                          trends === true
                          ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                          : " btnImgColor rounded px-3 mr-2 py-3"
                        } onClick={()=>{
                            setTrends(!trends)
                          }}>
                             <div className="example example-cover row">
                             <div className="col-12">
                             <img src={trnds} size="20px"></img>
                             </div>
                             <div className="col-12">

                              <span className="d-none d-md-inline">Trends</span>
                              </div>
                            </div>
                          </button>
                        </div>

                        <div className='col-3'>
                          <button className={
                         overlay === true
                         ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                         : " btnImgColor rounded px-3 mr-2 py-3"
                       } onClick={()=>{
                           setOverlay(!overlay)
                         }}>
                            <div className="example example-cover row">
                            <div className="col-12">
                            <img src={ovrlays} size="20px"></img>
                            </div>
                            <div className="col-12">

                             <span className="d-none d-md-inline">Overlays</span>
                             </div>
                           </div>
                         </button>
                        </div>

                        <div className='col-3'>
                         <button className={
                        historyTab === true
                        ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                        : " btnImgColor rounded px-3 mr-2 py-3"
                      } onClick={()=>{
                          setHistoryTab(!historyTab)
                        }}>
                           <div className="example example-cover row">
                           <div className="col-12">
                           <img src={histry} size="20px"></img>
                           </div>
                           <div className="col-12">

                            <span className="d-none d-md-inline">History</span>
                            </div>
                          </div>
                        </button>
                        </div>

                        <div className='col-3'>
                          <button className={
                         compare === true
                         ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                         : " btnImgColor rounded px-3 mr-2 py-3"
                       } onClick={()=>{
                           setCompare(!compare)
                         }}>
                            <div className="example example-cover row">
                            <div className="col-12">
                            <img src={compre} size="20px"></img>
                            </div>
                            <div className="col-12">

                             <span className="d-none d-md-inline">Compare</span>
                             </div>
                           </div>
                         </button>
                        </div>

                        <div className='col-3'>
                         <button className={
                        statistics === true
                        ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                        : " btnImgColor rounded px-3 mr-2 py-3"
                      } onClick={()=>{
                          setStatistics(!statistics)
                        }}>
                           <div className="example example-cover row">
                           <div className="col-12">
                           <img src={statistcs} size="20px"></img>
                           </div>
                           <div className="col-12">

                            <span className="d-none d-md-inline">Statistics</span>
                            </div>
                          </div>
                        </button>
                        </div>

                        <div className='col-3'>
                        <button className={
                       agp === true
                       ? " btnImgColor-hover bg-common text-white rounded px-3 mr-2 py-3"
                       : " btnImgColor rounded px-3 mr-2 py-3"
                     } onClick={()=>{
                         setAgp(!agp)
                       }}>
                          <div className="example example-cover row">
                          <div className="col-12">
                          <img src={Agp} size="20px"></img>
                          </div>
                          <div className="col-12">

                           <span className="d-none d-md-inline">Agp</span>
                           </div>
                         </div>
                       </button>
                       </div>*/}
                     </div>
                  </div>
                  :null }
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* exportableDays
              ?
                <div>
                <Spin spinning={isLoading}>
                <button type="button" className="btn btn-primary" onClick={handleExportData}>
                Export
                </button>
                </Spin>
                </div>:null*/}


      </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportData;
