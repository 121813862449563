import http from "../../utils/http-service";

import { API_BASE_URL, fetchCgmRecordUrl } from "../../utils/urls";

import {
  // For overview
  FETCH_CGM_RECORD_START,
  FETCH_CGM_RECORD_SUCCESS,
  FETCH_CGM_RECORD_FAILED,
  RESET_CGM_RECORD,

  // For sortedCgm
  // FETCH_SORTED_CGM_RECORD_START,
  // FETCH_SORTED_CGM_RECORD_SUCCESS,
  // FETCH_SORTED_CGM_RECORD_FAILED,

  //For export
  FETCH_EXPORT_CGM_RECORD_START,
  FETCH_EXPORT_CGM_RECORD_SUCCESS,
  FETCH_EXPORT_CGM_RECORD_FAILED,
  EXPORT_CGM_RECORD_CALCULATIONS,
  EXPORT_CGM_EVENTS,
// For History
FETCH_HISTORY_CGM_RECORD_START,
FETCH_HISTORY_CGM_RECORD_SUCCESS,
FETCH_HISTORY_CGM_RECORD_FAILED,
// for Time In Range Chart
  CGM_RECORD_CALCULATIONS,

  // ASSESMENT_URL
  ASSESMENT_URL,

} from "../actionTypes";
// import {encrypt, decrypt} from "../../../utils/encryption";
// import * as _ from "lodash";
import moment from "moment";


export const fetchCgmData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_CGM_RECORD_START,
  });
  let hoursArr=[];

try {
    // let cgm_start = data.timeFrom;
    // let cgm_end = data.timeTo;

// let duration = {
//   timeFrom:cgm_start,
//   timeTo:cgm_end
// }
    const res = await http.post(`${API_BASE_URL}${fetchCgmRecordUrl}`,{timeFrom:data.timeFrom, timeTo: data.timeTo});
    // // // // console.log("RES",res);
    let hiP;
    let loP;
    let avgP;
    let lowVal;
    let highVal;
    let hypogly;
    let hypoglycemiaReadings;
    let hyper;
    let hyperReadings;
    let tr;
    // let timeInRange;
    // let allReadings;
    let newTimeInRange=[];

    if(res.status===200){
      if(res.data && res.data.content.portal_cgm_record && res.data.content.portal_cgm_record.length>0){
        const {low , high } = res.data.content;
        let data = res.data.content.portal_cgm_record;
        let cal=findMinMax(data, "glucose");
        lowVal=cal.min;
        highVal=cal.max;
        let highArray=[];
        let lowArray=[];
        let avgArr=[];

        data.filter((reading, index)=>{
          if (reading.glucose >=high){
            highArray.push(reading);
          } else if (reading.glucose <= low){
            lowArray.push(reading);
          } else {
            avgArr.push(reading)
          }

          return 0;
        })

        // let dataLength= data.length;
        let highLength = highArray.length;
        let lowLength = lowArray.length;
        let avgLength = avgArr.length;




        hiP =  highLength && highLength !== undefined && highLength !== 0? (highArray.length/data.length)* 100:0;
        loP =  lowLength && lowLength !== undefined && lowLength !== 0? (lowArray.length/data.length)* 100:0;
        avgP =  avgLength && avgLength !== undefined && avgLength !== 0? (avgArr.length/data.length)* 100:0;




        dispatch(currentRecord({lowVal,highVal,loP, hiP, avgP}));


         hypogly = res.data.content.portal_cgm_record.filter((obj)=>(obj.glucose < low));

        hyper = res.data.content.portal_cgm_record.filter((obj)=>(obj.glucose > high));


        // // // console.log("CGM HYPO", hyper);

        hoursArr= makeAGPReadings(res.data.content.portal_cgm_record);
        // // // console.log("hoursArr", hoursArr);


       tr = res.data.content.portal_cgm_record.filter((obj)=>(obj.glucose <= high && obj.glucose >= low));
       // newTimeInRange = res.data.content.portal_cgm_record;

        hypogly=makeAGPReadings(hypogly);


        hyper=makeAGPReadings(hyper);
        tr=makeAGPReadings(tr);


for(let i = 0;  i<tr.length; i++){

  let obj = {
    time:i,
    hypo: hypogly[i].length,
    hyper: hyper[i].length,
    normal: tr[i].length,
  }
  newTimeInRange.push(obj)
}


        let hyp = hypogly.map((hour,index)=>{
          // let obj;
          // if(hour.length>0){
            let obj = {
              date: index,
              value: hour.length
            }
            return obj;

          // }
        });
        // // // console.log("HYP", hyp);
        hyp = hyp.filter((obj)=>obj!== undefined

        )
hypoglycemiaReadings= hyp;


let higher = hyper.map((hour,index)=>{
  // let obj;
  // if(hour.length>0){
    let obj = {
      date: index,
      value: hour.length
    }
    return obj;

  // }
});
// // // console.log("HYP", higher);
higher = higher.filter((obj)=>obj!== undefined);
hyperReadings = higher;


// let tRange = tr.map((hour,index)=>{
//   // let obj;
//   if(hour.length>0){
//     let obj = {
//       date: index,
//       value: hour.length
//     }
//     return obj;
//
//   }
// });
// // // // console.log("HYP", higher);
// tRange = tRange.filter((obj)=>obj!== undefined);
// timeInRange = tRange;
      }
    };

    let gift = res.data;
let conRead = gift.content.portal_cgm_record.map((ob)=>{
  // // console.log("OB",ob);

    let timeUTC = ob.time;
    // // console.log("ob.time", ob.time);
    // let converted = moment(timeUTC).utc();
    // // // console.log("CHECK", moment(converted).tz.guess());

  const gue =  moment.tz(timeUTC, "UTC");
    // // console.log("gue", gue);
    let conTime = moment(gue._d).format("HH:mm");
    // // console.log("conTime",conTime);


    let obj = {deviceSn: ob.deviceSn, time: gue._d, glucose: ob.glucose, time2: conTime}
    return obj;

});
// // // console.log("gift.content.insulin_record", gift.content.insulin_record);


  dispatch({ type: FETCH_CGM_RECORD_SUCCESS, payLoad: conRead });
  return {data:conRead,
    diet:gift.content.diet_record,
    exercise:gift.content.exercise_record,
    insulin:gift.content.insulin_record,
    medicine:gift.content.medication_record,
    hypoglycemiaReadings: hypoglycemiaReadings,
    hyperReadings:hyperReadings,
    timeInRange: newTimeInRange,
    calculation:{lowVal,highVal,loP, hiP, avgP,hoursArr},

  };
} catch (error) {
  dispatch({
    type: FETCH_CGM_RECORD_FAILED,
  });
}
};

// export const fetchSortedCgmData = () => async(dispatch) => {
//   dispatch({
//     type: FETCH_SORTED_CGM_RECORD_START,
//   });
// try {
//     const res = await http.get(`${API_BASE_URL}${fetchSortedCgmRecord}`);
//     let gift = res.data;
//   dispatch({ type: FETCH_SORTED_CGM_RECORD_SUCCESS, payLoad: gift.content });
//
//   return res;
// } catch (error) {
//   dispatch({
//     type: FETCH_SORTED_CGM_RECORD_FAILED,
//   });
// }
// };

export const fetchExportCgmData = (data) => async(dispatch) => {
  dispatch({
    type: FETCH_EXPORT_CGM_RECORD_START,
  });
try {
    let cgm_start = data.timeFrom;
    // let cgm_end = data.timeTo;
    let url = "";
// let duration = {
//   timeFrom:cgm_start,
//   timeTo:cgm_end
// }

    const res = await http.post(`${API_BASE_URL}${fetchCgmRecordUrl}`,{timeFrom:data.timeFrom, timeTo: data.timeTo});
    let lowVal;
    let highVal;
    let hiP;
    let loP;
    let avgP;
    let hoursArr;
    let hypogly;
    let hypoglycemiaReadings;
    let hyper;
    let hyperReadings;
    let tr;
    // let timeInRange;
    // let allReadings;
    let newTimeInRange=[];
    if(res.status===200){
      if(res.data && res.data.content.portal_cgm_record && res.data.content.portal_cgm_record.length>0){
        const {low , high } = res.data.content;

        let data = res.data.content.portal_cgm_record;
        let cal=findMinMax(data, "glucose");
        lowVal=cal.min;
        highVal=cal.max;
        let highArray=[];
        let lowArray=[];
        let avgArr=[];

        data.filter((reading, index)=>{
          if (reading.glucose >=high){
            highArray.push(reading);
          } else if (reading.glucose <= low){
            lowArray.push(reading);
          }else {
            avgArr.push(reading)
          }
          return 0;
        })

        // let dataLength= data.length;
        let highLength = highArray.length;
        let lowLength = lowArray.length;
        let avgLength = avgArr.length;




        hiP =  highLength && highLength !== undefined && highLength !== 0? (highArray.length/data.length)* 100:0;
        loP =  lowLength && lowLength !== undefined && lowLength !== 0? (lowArray.length/data.length)* 100:0;
        avgP =  avgLength && avgLength !== undefined && avgLength !== 0? (avgArr.length/data.length)* 100:0;
        hoursArr= makeAGPReadings(res.data.content.portal_cgm_record);




           hypogly = res.data.content.portal_cgm_record.filter((obj)=>obj.glucose < low);
            // // // console.log("OBJ", obj);
            // // // console.log("OBJ TYPE", typeof(obj.glucose));

            // if(obj.glucose < low){
            //   // // // console.log("FOUND BEFORE", obj);
            //
            //   return obj;
            // }
          // });

          hyper = res.data.content.portal_cgm_record.filter((obj)=>obj.glucose > high);
          // {
          // //  // // console.log("OBJ", obj);
          // //  // // console.log("OBJ TYPE", typeof(obj.glucose));
          //
          //  if(obj.glucose > high){
          //   //  // // console.log("FOUND BEFORE", obj);
          //
          //    return obj;
          //  }
         // });


          // // // console.log("CGM HYPO", hyper);

          hoursArr= makeAGPReadings(res.data.content.portal_cgm_record);
          // // // console.log("hoursArr", hoursArr);


         tr = res.data.content.portal_cgm_record.filter((obj)=>obj.glucose <= high && obj.glucose >= low);
         // {
         //  if (obj.glucose <= high && obj.glucose >= low){
         //      return obj;
         //    }
         //  });

          hypogly=makeAGPReadings(hypogly);


          hyper=makeAGPReadings(hyper);
          tr=makeAGPReadings(tr);


          for(let i = 0;  i<tr.length; i++){

            let obj = {
              time:i,
              hypo: hypogly[i].length,
              hyper: hyper[i].length,
              normal: tr[i].length,
            }
            newTimeInRange.push(obj)
          }



          // // // console.log("AFTER HYPO", hypogly);
          let hyp = hypogly.map((hour,index)=>{
            // let obj;
            // if(hour.length>0){
              let obj = {
                date: index,
                value: hour.length
              }
              return obj;

            // }
          });
          // // // console.log("HYP", hyp);
          hyp = hyp.filter((obj)=>obj!== undefined

          )
  hypoglycemiaReadings= hyp;


  let higher = hyper.map((hour,index)=>{
    // let obj;
    // if(hour.length>0){
      let obj = {
        date: index,
        value: hour.length
      }
      return obj;

    // }
  });
  // // // console.log("HYP", higher);
  higher = higher.filter((obj)=>obj!== undefined);
  hyperReadings = higher;


  // let tRange = tr.map((hour,index)=>{
  //   // let obj;
  //   if(hour.length>0){
  //     let obj = {
  //       date: index,
  //       value: hour.length
  //     }
  //     return obj;
  //
  //   }
  // });
  // // // // console.log("HYP", higher);
  // tRange = tRange.filter((obj)=>obj!== undefined);
  // timeInRange = tRange;

  // // // console.log("DATA ASSESMENT", url);
  dispatch(currentRecordforExport({lowVal,highVal,loP, hiP, avgP,fromDate:cgm_start, hoursArr,hypoglycemiaReadings,hyperReadings,newTimeInRange,assesmentUrl:url}));


      }
    };

    let gift = res.data;

    let conRead = gift.content.portal_cgm_record.map((ob)=>{

        let timeUTC = ob.time;
        // let converted = moment(timeUTC).utc();
        // // // console.log("CHECK", moment(converted).tz.guess());

      const gue =  moment.tz(timeUTC, "UTC");
        let conTime = moment(gue._d).format("HH:mm");


        let obj = {deviceSn: ob.deviceSn, time: gue._d, glucose: ob.glucose, time2: conTime}
        return obj;

    });
    // allReadings = gift.content.portal_cgm_record.filter((reading)=>reading.glucose > 2.0);
    // // // console.log("ALL RADINGS", allReadings);
    // // console.log("GIFT", gift);
    let contentNew = {
    diet_record:gift.content.diet_record,
    exercise_record : gift.content.exercise_record,
    high: gift.content.high,
    insulin_record: gift.content.insulin_record,
    low: gift.content.low,
    medication_record: gift.content.medication_record,
    portal_cgm_record:conRead};

  dispatch({ type: FETCH_EXPORT_CGM_RECORD_SUCCESS, payLoad: contentNew });

  // dispatch(eventsForExport({hypoglycemiaReadings:hypoglycemiaReadings,hyperReadings:hyperReadings,timeInRange:timeInRange}));


  return {data:contentNew.portal_cgm_record,
      diet:gift.content.diet_record,
      exercise:gift.content.exercise_record,
      insulin:gift.content.insulin_record,
      medicine:gift.content.medication_record,
      hypoglycemiaReadings: hypoglycemiaReadings,
      hyperReadings:hyperReadings,
      timeInRange: newTimeInRange,
      calculation:{lowVal,highVal,loP, hiP, avgP},

    };


} catch (error) {
  dispatch({
    type: FETCH_EXPORT_CGM_RECORD_FAILED,
  });
}
};


export const currentRecord = (data) => async(dispatch) => {
  dispatch({
    type: CGM_RECORD_CALCULATIONS, payLoad:data
  });

};
export const resetCgmReadings = () => async(dispatch) => {
  dispatch({
    type: RESET_CGM_RECORD
  });

};
export const currentRecordforExport = (data) => async(dispatch) => {
  dispatch({
    type: EXPORT_CGM_RECORD_CALCULATIONS, payLoad:data
  });

};

export const eventsForExport = (data) => async(dispatch) => {
  dispatch({
    type: EXPORT_CGM_EVENTS, payLoad:data
  });

};


export const fetchCgmDataForHistory = (data, daysDif) => async(dispatch) => {
  // // // console.log("DATA", data);

  dispatch({
    type: FETCH_HISTORY_CGM_RECORD_START,
  });
try {
    let cgm_start = data.timeFrom;
    // let cgm_end = moment(data.timeFrom).add(1, 'days');

// let duration = {
//   timeFrom:cgm_start,
//   timeTo:cgm_end
// }

    const res = await http.post(`${API_BASE_URL}${fetchCgmRecordUrl}`,{timeFrom:data.timeFrom, timeTo: data.timeTo});
    let weeksArr=[];


    if(res.status===200){
      if(res.data && res.data.content.portal_cgm_record && res.data.content.portal_cgm_record.length>0){
        let data = res.data.content.portal_cgm_record;
        let firstWeekStartDate = moment(cgm_start);

        // console.log('CGM_START', cgm_start);
        // let endDate = moment(data.timeTo);
        // endDate = moment(endDate._d);
        // let lastDate = endDate._d;
        // console.log("END DATE", endDate);
        // console.log("firstWeekStartDate", firstWeekStartDate);
        // console.log("daysDif", daysDif);


        // // console.log("Last DATE", lastDate);

        // let days = endDate.diff(moment(firstWeekStartDate), 'days') ;
        // let days = moment.duration(endDate.diff(firstWeekStartDate)).asDays();
        // console.log("DIFF", days);
        let numberOfWeeks = Math.ceil(daysDif / 1);
        let weekStartDate="";
        let weekEndDate="";
        for(let i=0; i<numberOfWeeks; i++){
          if(i === 0){
            weekStartDate= firstWeekStartDate;
            weekEndDate = moment(firstWeekStartDate).add(24, 'hours');
          }
          let week=[];
          if (data && data.length > 0) {
            let weekSD = weekStartDate;
            let weekED = weekEndDate;

            data.map((day, index)=> {

            let p1 = moment(weekSD);
            // // // console.log("START DATE", p1);

            let p2 = moment(day.time);
            // // // console.log("reading DATE", p2);

            let p3 = moment(weekED);
            // // // console.log("END DATE", p3);


            if (p2.isAfter(p1) && p2.isBefore(p3)){
              // // // console.log("READING TIME", p2);
              let timeUTC = day.time;
              // // console.log("day.time", day.time);
              // let converted = moment(timeUTC).utc();
              // // // console.log("CHECK", moment(converted).tz.guess());

            const gue =  moment.tz(timeUTC, "UTC");
              // // console.log("gue", gue);
              let conTime = moment(gue._d).format("HH:mm");
              // // console.log("conTime",conTime);

              let obj = {deviceSn: day.deviceSn, time: gue._d, glucose: day.glucose, time2:conTime}
              week.push(obj);
            }
            return 0;
          })
          }
          let obj = {weekStartDate:new Date(weekStartDate).toString(),
            readings:[week]}
          weeksArr.push(obj);
          // weekStartDate= moment(weekEndDate).add(1, 'days');
          // weekEndDate = moment(weekEndDate).add(7, 'days');
          weekStartDate= moment(weekEndDate)
          weekEndDate = moment(weekEndDate).add(24, 'hours');
        };


      }
    };

    // let gift = res.data;

  dispatch({ type: FETCH_HISTORY_CGM_RECORD_SUCCESS, payLoad: weeksArr });
  // console.log("WEEK ARRAY", weeksArr);
  return weeksArr;
} catch (error) {
  dispatch({
    type: FETCH_HISTORY_CGM_RECORD_FAILED,
  });
}
};


export const makeAssesmentUrl=(duration)=> async(dispatch)=>{
  // // // console.log("DURAT", duration);
  dispatch({ type: ASSESMENT_URL, payLoad: duration.url });

}














const findMinMax=(arr,key)=> {
const datas = arr.map((node) => node[key]);
return {
  min: Math.min(...datas),
  max: Math.max(...datas),
}
}

const makeAGPReadings=(data)=>{
  let eachHourData=[];
  let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];

    for(let i =0;i<24; i++){
      let obj = data.filter((dayInner, index)=>{
      return  parseInt(moment(dayInner.time).format("HH")) === parseInt(timeSlots[i])

      })
      obj.sort((a, b)=>{return a.glucose-b.glucose});
      eachHourData.push(obj);
    }

  return eachHourData;
}
