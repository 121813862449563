import React, { Component } from "react";
import { connect } from "react-redux";

import { Route, Redirect } from "react-router-dom";

class GuestRoute extends Component {
  render() {

    const { isAuthenticated, component: Component, ...rest } = this.props;
    if (this.props.path === "/" && !isAuthenticated) {
      return <Redirect to="/auth/login" />;
    } else if(isAuthenticated && localStorage.getItem("url") !== "/dashboard" &&
        localStorage.getItem("url") !== "/auth/login" && localStorage.getItem("url") !== "/") {

    }
    return (
      <div>
        <Route
          {...rest}
          render={(props) =>
            isAuthenticated || localStorage.getItem("AidexAuthToken")? (
              <Redirect to="/dashboard" />
            )
            :(
              <Component {...props} />

            )
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { token } = state.auth;

  return {
    isAuthenticated: !!token,
  };
}

export default connect(mapStateToProps)(GuestRoute);
