import React from 'react';
import SupportComp from '../Components/Support/SupportComp';

const SupportPage = () => {
    return (
        <div>
             <div className="container-fluid h-100 mt-2">

        < SupportComp/>
</div>
        </div>
    );
};

export default SupportPage;
