import React, { useState, useEffect }from "react";
// import ClinicianDashboard from "../clinician-dashboard";
// import PatientDashboard from "../patient-dashboard";

import Sidebar from "./Sidebar";
// import { logout } from "../../redux/actions/auth";
import { useLocation } from "react-router-dom";
// import { connect } from "react-redux";
import Navbar from "./Navbar";
import { NavLink } from "react-router-dom";

import ExportData from "../Export/ExportData";


import { useSelector } from "react-redux";


// import DoctorDashboard from "../doctor-dashboard";

export default function SharedLayout(props) {
  let location = useLocation();
  const { userDetails } = useSelector((state) => {return state.user});
  // const [showSidebar, setShowSidebar]= useState(false);
  //
  useEffect(() => {

    // if(userDetails && userDetails.user && userDetails.user.role.length===1 && userDetails.user.role[0]=== 4){
    //
    //     setShowSidebar(true);
    // }
    // if(userDetails && userDetails.user && userDetails.user.role.length>1){
    //   for(let i = 0; i<userDetails.user.role.length; i++) {
    //     if(userDetails.user.role[i] === 4 ) {
    //
    //       setShowSidebar(true);
    //     }
    //   }
    // }
    }, [userDetails]);

  // if(userDetails && userDetails.user && userDetails.user.role.length===1 && userDetails.user.role[0]=== 4){

  //     setShowSidebar(true);
  // }
  // if(userDetails && userDetails.user && userDetails.user.role.length>1){
  //   for(let i = 0; i<userDetails.user.role.length; i++) {
  //     if(userDetails.user.role[i] === 4) {

  //       setShowSidebar(true);
  //     }
  //   }
  // }


  const [collapsed]= useState(true);

        return (
            <div className="row mw-100 m-auto shared-layout " style = {{background: "#fff" }} >
                <div className = "w-full p-0 m-0 header col-12 shared-layout-topbar print-btn" >
                    <Navbar collapsed = {collapsed}/>
                </div>
                {/*userDetails && userDetails.user && userDetails.user.role.includes(4)===true?*/}
                <div className="col-12 shared-layout-main">
                  <div className="row h-100 mw-100 m-auto">
                  <div className="col-12 col-md-2 bg-common rounded shared-layout-main-sidebar mt-2 print-btn">
                  <Sidebar collapsed = {collapsed}/>

                  </div>
                  {/*:null*/}
                  <main className="col  bg-faded ">
                  <div className="row mw-100 m-auto h-100">
                  <div className="col-12 bg-light rounded mx-1 pl-md-2 pl-0 py-2 print-btn">
                  {location.pathname !== "/export-data" && location.pathname !== "/Support" && location.pathname !== "/History"?
                   // userDetails && userDetails.user &&
                   //  userDetails.user.role.includes(1)===false &&
                   //  userDetails.user.role.includes(0)===false?
                  <ExportData/>
                  : null}


                  </div>
                    <div className="col-12 h-100 bg-light rounded mx-1 pl-md-2 pl-0 py-2">


                    { props.children }

                    </div>
                  </div>



                  </main>
                  </div>

                </div>

                <NavLink
                className={location.pathname === "/Feedback"?"d-none":"feedback-btn sticky print-btn"}
                  to="/Feedback"

                >
                  <p className="m-0 print-btn">Feedback</p>
                </NavLink>

        </div>
        );
}
