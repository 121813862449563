import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import SharedLayout from "../../Components/Common/SharedLayout";

class AuthenticatedRoute extends Component {
  render() {

    const { isAuthenticated, component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated && !!localStorage.getItem("AidexAuthToken") ? (
            <SharedLayout>

              <Component {...props} />
              </SharedLayout>

          ) : isAuthenticated === false && !!localStorage.getItem("AidexAuthToken") ?
          <SharedLayout>

            <Component {...props} />
            </SharedLayout>

          :(
            <Redirect to="/auth/login" />
          )
        }
      />
    );
  }
}

function mapStateToProps(state) {
  const { token } = state.auth;

  return {
    isAuthenticated: !!token,
  };
}

export default connect(mapStateToProps)(AuthenticatedRoute);
