import React, { useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import $ from "jquery";
import Loading from '../../Common/Loading';


am4core.useTheme(am4themes_animated);

function EventsChart(props) {
  const [showGraph, setShowGraph]=useState(false);


// // // console.log("PROPS DATA", props);

 let color2="#00007a";
  useEffect(() => {
    let timeSlots=["00", "01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];

    let chart = am4core.create(props.graph, am4charts.XYChart);

    chart.fontSize =14;
    chart.numberFormatter.numberFormat = "#";

    // // // // console.log("DATAPOINTS :", props.dataPoints);
    let list = props.dataPoints;
    // // // console.log("LIST", list);
    let customData=[];
    for(let i=0; i<24; i++){
      // let newDate;
      let newBg;
      for(let j=0; j<list.length; j++){
        if(parseInt(list[j].date)===i){
        // newDate = list[j].date;
        newBg= list[j].value;

        }
      }


      let obj={


        //After fixes
        date:timeSlots[i],

        value:newBg!== undefined?newBg.toFixed(1):null
      }
      customData.push(obj);
    }
    // // // console.log("CUSTOM", customData);
    chart.data = customData;
    // // // // console.log("AVGBG", list);

// Create axes


    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'date';
    categoryAxis.title.text = "[bold]Time  ";
    categoryAxis.renderer.grid.template.location = 1;
    categoryAxis.renderer.minGridDistance = 10;



    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text="[bold]No. of Events "
    valueAxis.min = 0;
    valueAxis.renderer.minGridDistance = 90;
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "value";
    series.dataFields.categoryX = "date";
    series.tooltipText = "{valueY.value}";
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.dy = -6;
    series.columns.template.fill = am4core.color(color2);
    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.hiddenState.properties.opacity = 1;
    series.tooltip.hiddenState.properties.visible = true;
    let columnTemplate = series.columns.template;
    columnTemplate.width = 12;
    columnTemplate.column.cornerRadiusTopRight = 8;
    columnTemplate.column.cornerRadiusTopLeft = 8;
    columnTemplate.strokeOpacity = 0;
    series.heatRules.push({
      target: columnTemplate,
      property: "fill",
      dataField: "valueY",

    });
    let cursor = new am4charts.XYCursor();
    cursor.behavior = "panX";
    chart.cursor = cursor;
    cursor.lineX.disabled = true;

    chart.events.on("datavalidated", function () {
      categoryAxis.zoomToCategories(
          chart.data[0],
          chart.data[chart.data.length - 1],

          false,
          true
      );
    });
    chart.events.on('ready', () => {
      setShowGraph(true);
    });
    series.mainContainer.mask = undefined;
    return () => {
          chart.dispose();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataPoints]);

  return (<div className="w-100">
  <div className={showGraph?"d-none":" w-100"}>                    <Loading/>
</div><div id={props.graph} className={showGraph?"":"d-none"} style={{ width: "100%", minHeight: "600px" }}></div> </div>);
}
export default EventsChart;
