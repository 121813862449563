import { combineReducers } from "redux";
import auth from "./auth";
import user from "./user";
import cgm from "./cgm";



const rootReducer = combineReducers({
  auth,
  user,
  cgm,
});

export default (state, action) =>
  rootReducer(action.type === "USER_LOGGED_OUT" ? undefined : state, action);
